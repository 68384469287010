.home__hero-bg {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;

  background-color: var(--hero-bg-color);
}

.home__hero {
  padding: 9.270% 10% 6.459%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;

  color: var(--hero-font-color);
  background: url(./hero-bg.webp) center left / cover no-repeat;

  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
}

.hero__cards {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
}

@media (max-width: 1280px) {
  .home__hero {
    padding-top: 120px;
  }
}

@media (max-width: 768px) {
  .home__hero {
    padding: 146px 5.129% 6.667%;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .hero__cards {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
  }
}