@import url(./animation.css);

.menu {
  padding: 116px 6.9795% 210px 10.7295%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  min-height: 100vh;

  color: var(--menu-font-color);
  background-color: var(--menu-bg-color);
}

@media (max-width: 768px) {
  .menu {
    padding: 84px 4.103% 92px 5.642%;
    
    display: grid;
    grid-template: min-content 44px 62px auto / 1fr 122px;
  }
}