.faq__accordion.accordion.accordion-flush .accordion-item {
  position: relative;

  padding-right: 14.214%;

  height: min-content;

  color: var(--faq-font-color);
  background-color: var(--faq-bg-color);

  border: 2px solid var(--faq-item-border-color);
  border-radius: 42px;

  overflow: hidden;
}

@media (max-width: 768px) {

  .faq__accordion.accordion.accordion-flush .accordion-item {
    padding-right: 0;
    border-radius: 20px;
  }
}

.faq__accordion .accordion-item::after {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  padding-right: 0.757%;
  padding-bottom: 1.515%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 14.214%;
  height: 100%;

  font-size: 48px;
  line-height: 1.083;
  text-transform: uppercase;
  color: var(--faq-font-color);

  background-color: var(--faq-bg-color);

  border-left: 2px solid var(--faq-item-border-color);
  border-top-right-radius: 42px;
  border-bottom-right-radius: 42px;

  transition: all 0.25s ease-in-out;
}

.faq__accordion .accordion-item.open::after {
  content: '';
  background-color: var(--faq-item-sidebar-bg-open-color);
}

@media (max-width: 768px) {

  .faq__accordion .accordion-item::after,
  .faq__accordion .accordion-item.open::after {
    content: none;
  }
}

.faq__accordion .accordion-header {
  padding-right: 20px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
}

.faq__accordion .accordion-header-number {
  padding-top: 3.2%;
  padding-left: 3.3%;

  width: 17.5%;

  font-size: 24px;
  line-height: 2.166;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .faq__accordion .accordion-header-number {
    padding-top: 0;

    font-size: 18px;
    line-height: 3.25;
  }
}

@media (max-width: 480px) {
  .faq__accordion .accordion-header-number {
    font-size: 16px;
  }
}

.faq__accordion .accordion-header-box {
  display: flex;
  flex-flow: column nowrap;
}

.faq__accordion .accordion-header-text {
  margin-top: 32px;
  margin-bottom: 20px;

  font-size: 24px;
  line-height: 1.208;
}

@media (max-width: 768px) {
  .faq__accordion .accordion-header-text {
    margin-top: 16px;
    margin-bottom: 18px;

    font-size: 18px;
    line-height: 1.375;
  }

  .faq__accordion .accordion-item.open .accordion-header-text {
    margin-bottom: 16px;
  }
}

@media (max-width: 480px) {
  .faq__accordion .accordion-header-text {
    font-size: 16px;
  }
}

.faq__accordion .accordion-button,
.faq__accordion .accordion-button:not(.collapsed) {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;

  width: max-content;

  font-size: 16px;
  line-height: 1.75;
  text-decoration-line: underline;

  color: var(--faq-font-color);
  background-color: transparent;

  box-shadow: none;

  transition: all 0.2s linear 0s;
}

.faq__accordion .accordion-button::after {
  content: none;
}

.faq__accordion .accordion-button:hover,
.faq__accordion .accordion-button:focus {
  color: var(--faq-item-sidebar-bg-open-color);
  box-shadow: none;
}

.faq__accordion .accordion-item.open .accordion-button {
  margin-bottom: 20px;
}

.faq__accordion .accordion-button.layer,
.faq__accordion .accordion-button.layer:not(.collapsed) {
  margin: 0;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 14.214%;

  border-top-right-radius: 42px;
  border-bottom-right-radius: 42px;

  z-index: 5;
}

.faq__accordion .accordion-item.open .accordion-button.layer {
  margin-bottom: 0;
}


@media (max-width: 768px) {

  .faq__accordion .accordion-button,
  .faq__accordion .accordion-button:not(.collapsed) {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    margin: 0;
    padding: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    z-index: 3;
  }

  .faq__accordion .accordion-button.layer,
  .faq__accordion .accordion-button.layer:not(.collapsed) {
    display: none;
  }
}

.faq__accordion .accordion-body {
  margin: 0;
  padding: 24px 4.143% 30px 17.752%;

  font-family: var(--machina-bold);
  font-size: 18px;
  font-weight: 800;
  line-height: 1.333;

  color: var(--faq-bg-color);
  background-color: var(--faq-font-color);
}

:-moz-any(main) .faq__accordion .accordion-body {
  font-weight: 400;
}

@media (max-width: 768px) {
  .faq__accordion .accordion-body {
    padding: 12px 4.143% 16px 15%;

    font-size: 15px;
    line-height: 1.214;
  }
}

@media (max-width: 480px) {
  .faq__accordion .accordion-body {
    font-size: 14px;
  }
}

.arrow-container {
  position: relative;

  width: 34px;
  height: 24px;

  transition: all 0.25s ease-in-out 0s;
}

.right-arrow-line,
.left-arrow-line {
  position: absolute;
  top: 0;

  margin-top: 14px;

  width: 20px;
  height: 2px;

  background-color: var(--faq-font-color);
  border-radius: 1px;

  transition: all 0.25s ease-in-out 0s;
}

.right-arrow-line {
  right: 0;
  transform: rotate(-45deg)
}

.left-arrow-line {
  left: 0;
  transform: rotate(45deg)
}

.accordion-button.layer:hover .arrow-container {
  transform: scale(1.2);
}

.accordion-button.layer:hover .right-arrow-line,
.accordion-button.layer:hover .left-arrow-line,
.accordion-button.layer:focus .right-arrow-line,
.accordion-button.layer:focus .left-arrow-line {
  background-color: var(--faq-item-sidebar-bg-open-color);
}

.accordion-item.open .accordion-button.layer:hover .right-arrow-line,
.accordion-item.open .accordion-button.layer:hover .left-arrow-line,
.accordion-item.open .accordion-button.layer:focus .right-arrow-line,
.accordion-item.open .accordion-button.layer:focus .left-arrow-line {
  background-color: var(--faq-font-color);
}

.accordion-item.open .right-arrow-line {
  transform: rotate(45deg)
}

.accordion-item.open .left-arrow-line {
  transform: rotate(-45deg)
}

.accordion-body span {
  margin-bottom: 8px;
}

.accordion-body span:last-child {
  margin-bottom: 0;
}