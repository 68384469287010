.possibilities__items {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template: 1fr / repeat(4, 1fr);
  gap: 2.184%;
}

@media (max-width: 1400px) and (min-width: 769px) {
  .possibilities__items {
    grid-template: 1fr / repeat(3, 1fr);
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .possibilities__items {
    grid-template: 1fr / repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .possibilities__items {
    grid-template: 1fr / repeat(2, 1fr);
    gap: 12px;
  }
}

@media (max-width: 600px) {
  .possibilities__items {
    grid-template: 1fr / 1fr;
    gap: 8px;
  }
}