.company .hero__body.body {
  margin: 0 8.585% 0 7.256%;
  padding: 1.814% 1.330% 1.330% 2.056%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  background: var(--company-hero-body-bg-color);
  border-radius: 30px;
}

.company .body__content.content {
  flex: 0 0 49.550%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .company .hero__body.body {
    margin: 0;
    padding: 0 20px 20px;

    flex-flow: column-reverse nowrap;
    justify-content: space-between;
    align-items: stretch;

    border-radius: 34px;
  }
}