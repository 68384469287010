.company__start.start {
  position: relative;

  padding: 8.020% 6.927% 6.979%;

  width: 100%;
  height: auto;

  color: var(--company-start-font-color);
  background-color: var(--company-start-bg-color);
}

@media (max-width: 768px) {
  .company__start.start {
    padding: 20% 5.128% 15.897%;
  }
}