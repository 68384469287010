.courses__suitable.suitable {
  padding: 7.1875% 6.928% 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;

  color: var(--corses-suitable-font-color);
  background-color: var(--corses-suitable-bg-color);
}

.courses__suitable .suitable__items {
  margin: 0;
  padding: 0;

  margin-top: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  width: 59.009%;
}

@media (max-width: 768px) {
  .courses__suitable.suitable {
    padding: 36px 5.642% 0;

    flex-flow: column nowrap;
  }

  .courses__suitable .suitable__items {
    margin-top: 0;

    width: 100%;
  }
}