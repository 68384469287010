.faq__accordion.accordion.accordion-flush {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template: auto / repeat(2, 1fr);

  row-gap: 24px;
  column-gap: 32px;
}

@media (max-width: 1440px) {
  .faq__accordion.accordion.accordion-flush {
    grid-template: auto / 1fr;
    row-gap: 12px;
  }
}

@media (max-width: 480px) {
  .faq__accordion.accordion.accordion-flush {
    row-gap: 6px;
  }
}