.company__possibilities.possibilities {
  padding: 7.084% 8.021% 6.771% 6.145%;

  width: 100%;
  height: auto;

  color: var(--company-possibilities-font-color);
  background: var(--company-possibilities-bg-color)
}

@media (max-width: 768px) {
  .company__possibilities.possibilities {
    padding: 48px 5.128% 40px;
  }
}