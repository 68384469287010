.menu__msgr-items {
  margin: 0;
  padding: 0;

  margin-top: 264px;

  display: flex;
  flex-flow: column nowrap;
}

.menu__msgr-item {
  margin-bottom: 15px;

  opacity: 0;
}

.menu__msgr-item:last-child {
  margin-bottom: 0;
}


@media (max-width: 1440px) {
  .menu__msgr-items {
    margin-top: 232px;
  }
}

@media (max-width: 1280px) {
  .menu__msgr-items {
    margin-top: 200px;
  }
}

@media (max-width: 1024px) {
  .menu__msgr-items {
    margin-top: 176px;
  }
}

@media (max-width: 960px) {
  .menu__msgr-items {
    margin-top: 140px;
  }
}

@media (max-width: 768px) {
  .menu__msgr-items {
    grid-column: 2 / 3;
    grid-row: 4 / 5;

    align-items: center;

    margin-top: 0;
  }
}