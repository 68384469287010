.header {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 34px 6.9795%;

  width: 100%;
  height: 116px;

  background-color: transparent;

  z-index: 2;
}

@media (max-width: 768px) {
  .header {
    padding: 40px 4.103% 40px 5.642%;

    height: 128px;
  }
}