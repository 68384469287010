.content__video-placeholdeer,
.item__video-placeholdeer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 99.6%;
  height: 99.6%;

  border-radius: 30px;
}

.students__reviews .item__video-placeholdeer {
  left: auto;
  right: auto;
}

.content__video-placeholdeer-btn,
.item__video-placeholdeer-btn {
  position: relative;

  width: 140px;
  height: 140px;

  border: 1px solid var(--why-mds-play-btn-border-color);
  border-radius: 70px;
  background-color: var(--why-mds-play-btn-bg-color);

  z-index: 12;

  transition: all 0.2s linear 0s;
}

.content__video-placeholdeer-btn::before,
.item__video-placeholdeer-btn::before {
  position: absolute;
  top: 29px;
  left: 27px;

  content: "";
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 80px solid var(--why-mds-play-btn-triangle-color);

  transform: rotate(90deg) scale(0.6);
  transition: all 0.1s linear 0s;
}

.content__video-placeholdeer-btn:hover,
.content__video-placeholdeer-btn:focus,
.item__video-placeholdeer-btn:hover,
.item__video-placeholdeer-btn:focus {
  transform: scale(0.96);
}

.content__video-placeholdeer-btn:active,
.item__video-placeholdeer-btn:active {
  transform: scale(1);
}

.content__video-placeholdeer-btn:hover::before,
.content__video-placeholdeer-btn:focus::before,
.item__video-placeholdeer-btn:hover::before,
.item__video-placeholdeer-btn:focus::before {
  border-bottom-color: var(--why-mds-play-btn-triangle-hover-color);
}

.content__video-placeholdeer-btn:active::before,
.item__video-placeholdeer-btn:active::before {
  border-bottom-color: var(--why-mds-play-btn-triangle-color);
}

@media (max-width: 1024px) {

  .content__video-placeholdeer-btn,
  .item__video-placeholdeer-btn {
    width: 92px;
    height: 92px;

    border-radius: 46px;
  }

  .content__video-placeholdeer-btn::before,
  .item__video-placeholdeer-btn::before {
    top: 19px;
    left: 20px;

    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 52px solid var(--why-mds-play-btn-triangle-color);
  }
}

.reviews .item__video-container {
  position: relative;
}