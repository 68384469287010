@keyframes creeping {
  from {
    transform: translateX(0);
  }

  to {
    /* transform: translateX(-3380px); */
    transform: translateX(-7940px);
  }
}

.why-mds__creeping-line {
  position: relative;
  margin: 84px 0 122px;
  height: 192px;
  white-space: nowrap;
}

.creeping-line__box {
  position: absolute;

  margin: 0 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  /* width: 8280px; */
  width: 19680px;

  animation-name: creeping;
  animation-duration: 16s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-delay: 0s;
  animation-fill-mode: none;
}

.creeping-line__text {
  margin-right: 170px;

  display: block;

  /* width: 1520px; */
  width: 3800px;
  height: 192px;

  font-family: var(--coanda);
  font-size: 230px;
  font-weight: 700;
  line-height: 1;
}

.creeping-line__text:last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .why-mds__creeping-line {
    margin: 112px 0 121px;
  }
}