.menu__network-link {
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 7px;

  width: 180px;
  height: 44px;

  font-size: 16px;
  line-height: 1.75;
  color: var(--btn-and-link-font-color);

  background-color: transparent;
  border: 1px solid var(--btn-and-link-border-color);
  border-radius: 24px;

  -webkit-box-shadow: inset 0 0 0 0 var(--btn-and-link-border-color);
  box-shadow: inset 0 0 0 0 var(--btn-and-link-border-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.menu__network-link:hover,
.menu__network-link:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--menu-msgr-link-hover-bg-color);
  box-shadow: inset 0 10em 0 0 var(--menu-msgr-link-hover-bg-color);
  color: var(--menu-msgr-link-hover-font-color);
}

.menu__network-link:active {
  -webkit-box-shadow: inset 0 0 0 0 var(--btn-and-link-border-color);
  box-shadow: inset 0 0 0 0 var(--btn-and-link-border-color);
  color: var(--btn-and-link-font-color);
}

@media (max-width: 1024px) {
  .menu__network-link {
    padding-top: 5px;
    line-height: 1;

    width: 114px;
    height: 28px;
  }
}

@media (max-width: 768px) {
  .menu__network-items {
    margin: 0 3px 0 auto;
  }
}