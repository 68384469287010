.projects__head.head {
  padding: 0 18px;
  margin-bottom: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  width: 100%;
  height: auto;
}

.projects__head .head__topography {
  margin-bottom: 10px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.projects__head .head__count-bar {
  display: grid;
  gap: 2px;

  margin: 0;
  padding: 0;

  width: 100%;
  height: 5px;

  z-index: 15;
}

@media (max-width: 768px) {
  .projects__head.head {
    padding: 0 4.615%;
    margin-bottom: 0;
  }

  .projects__head .head__topography {
    margin-bottom: 0;

    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}