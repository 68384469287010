/* @keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
} */

.item__star {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 14.5%;
}

.star__number {
  position: relative;
  padding-top: 68px;

  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.093;
}

/* .content__item.item:first-child .star__number {
  padding-top: 50px;
} */

/* .star__number:after {
  content: url(./star.svg);
  position: absolute;
  top: 32px;
  left: -44px;

  width: 108px;
  height: 108px;

  animation-name: rotating;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-delay: 0s;
  animation-fill-mode: none;
} */

/* .content__item.item:first-child .star__number:after {
  top: 14px;
} */

.star__number:after {
  content: '';
  position: absolute;
  top: 54px;
  left: -21px;

  width: 64px;
  height: 64px;

  border: 1px solid var(--white);
  border-radius: 50%;
}

@media (max-width: 1024px) {

  .star__number {
    padding-top: 60px;
    padding-left: 60px;

    font-size: 38px;
    line-height: 1.105;
  }

  /* .content__item.item:first-child .star__number {
    padding-top: 72px;
  } */

  /* .content__item.item:first-child .star__number:after {
        top: 40px;
      } */

  /* .star__number:after {
    top: 28px;
    left: 18px;
  } */

  .star__number:after {
    top: 50px;
    left: 41px;
  }
}

/* courses stages */
.stages .item__star.star {
  position: absolute;
  top: -86px;
  left: -4px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 98px;
  height: 98px;
}

.stages .star__number {
  position: relative;

  margin: 0;
  padding: 0;

  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
}

/* .stages .star__number:after {
  content: url(./star-orange.svg);
  position: absolute;
  top: -22px;
  left: -31px;

  width: 98px;
  height: 98px;
} */

.stages .star__number:after {
  content: '';
  position: absolute;
  top: -8px;
  left: -19px;

  width: 72px;
  height: 72px;

  border: 2px solid var(--safety-orange);
  border-radius: 50%;
}