/* home hero */
.courses-card__text {
  display: inline;

  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.093;
}

@media (max-width: 1280px) {
  .courses-card__text {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .courses-card__text {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .courses-card__text {
    font-size: 24px;
    line-height: 1.208;
  }
}

/* home start */
.start__text {
  margin-bottom: 26px;

  max-width: 824px;

  font-size: 18px;
  line-height: 1.556;
  text-align: center;
}

.card__text,
.accordion-card__text {
  margin: 0 38px 48px;

  font-size: 18px;
  line-height: 1.556;
}

@media (max-width: 1024px) {
  .card__text {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .start__text {
    margin: 0 auto 24px;

    width: 93.583%;

    font-size: 16px;
    line-height: 1.75;
  }

  .accordion-card__text {
    margin: 0 22px;

    font-size: 16px;
    line-height: 1.75;
  }
}

/* home why mds */
.item__text {
  margin: 0;

  font-size: 18px;
  line-height: 1.556;
}

@media (max-width: 768px) {
  .item__text {
    margin: 0;

    font-size: 14px;
    line-height: 1.714;
  }
}

/* home team */
.badge__text {
  color: rgba(255, 255, 255, 0.50);
  font-size: 18px;
  line-height: 1.556;
  margin-bottom: 0;
}

@media (max-width: 1280px) {
  .badge__text {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .badge__text {
    margin-bottom: 0;
    font-size: 16px;
  }
}

@media (max-width: 860px) {
  .badge__text {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .badge__text {
    font-size: 18px;
    line-height: 1.556;
  }
}

/* contact form */
.contact .form__text {
  font-size: 15px;
  line-height: 1.266;
}

.contact .form__text:nth-child(2) {
  margin: 0 auto 24px;
  width: 100%;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .contact .form__text {
    font-size: 14px;
    line-height: 1.285;
  }
}

/* courses suitable */
.suitable__item .item__text {
  grid-row: 2 / 3;
  grid-column: 1 / 3;

  font-size: 18px;
  line-height: 1.556;
}

@media (max-width: 1280px) and (min-width: 769px) {
  .suitable__item .item__text {
    font-size: 16px;
  }
}

@media (max-width: 854px) and (min-width: 769px) {
  .suitable__item .item__text {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .suitable__item .item__text {
    font-size: 14px;
    line-height: 1.714;
  }
}

/* courses stages */
.stages .item__text {
  width: 100%;

  font-size: 18px;
  font-weight: 400;
  line-height: 1.555;
}

/* company hero */
.company .content__text {
  font-size: 24px;
  line-height: 1.208;
}

@media (max-width: 1600px) and (min-width: 769px) {
  .company .content__text {
    font-size: 20px;
  }
}

@media (max-width: 1400px) and (min-width: 769px) {
  .company .content__text {
    font-size: 16px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .company .content__text {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .company .content__text {
    margin-bottom: 66px;

    font-size: 15px;
    line-height: 1.266;
  }
}