.home__start {
  padding: 6.5625% 9.6875% 9.7916%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  user-select: none;

  background-color: var(--start-bg-color);
}

.bold {
  font-weight: 800;
}

@media (max-width: 768px) {
  .home__start {
    padding: 57px 2.052% 74px;
  }
}