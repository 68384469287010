.body__btns {
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.body__btn {
  position: relative;

  width: 42px;
  height: 42px;

  background: white;

  border: 1px solid var(--btn-arrow-border-color);
  border-radius: 21px;

  -webkit-box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);
  box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

/* hover / focus  */
.body__btn:hover,
.body__btn:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--btn-arrow-bg-hover-color);
  box-shadow: inset 0 10em 0 0 var(--btn-arrow-bg-hover-color);
  border-color: var(--btn-arrow-border-hover-color);
}

.body__btn:hover .btn-arrow__top-line,
.body__btn:hover .btn-arrow__bot-line,
.body__btn:focus .btn-arrow__top-line,
.body__btn:focus .btn-arrow__bot-line {
  background-color: var(--btn-arrow-line-hover-color);
}

/* active */
.body__btn:active {
  -webkit-box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);
  box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);
  border-color: var(--btn-arrow-border-color);
}

.body__btn:active .btn-arrow__top-line,
.body__btn:active .btn-arrow__bot-line {
  background-color: var(--btn-arrow-line-color);
}

.btn-arrow__top-line,
.btn-arrow__bot-line {
  position: absolute;
  left: 0;

  display: block;

  width: 10px;
  height: 2px;

  background-color: var(--btn-arrow-line-color);
  border-radius: 1px;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.prevs .btn-arrow__top-line {
  top: 16px;
  left: 14.5px;
  transform: rotate(-45deg);
}

.prevs .btn-arrow__bot-line {
  bottom: 16px;
  left: 14.5px;
  transform: rotate(45deg);
}

.next .btn-arrow__top-line {
  top: 16px;
  left: 15.5px;
  transform: rotate(-135deg);
}

.next .btn-arrow__bot-line {
  bottom: 16px;
  left: 15.5px;
  transform: rotate(135deg);
}

@media (max-width: 992px) and (min-width: 520px) {
  .body__btns {
    left: 8px;
    right: 8px;
  }

  .body__btn {
    width: 32px;
    height: 32px;
  }

  .prevs .btn-arrow__top-line {
    top: 11px;
    left: 9px;
  }

  .prevs .btn-arrow__bot-line {
    bottom: 11px;
    left: 9px;
  }

  .next .btn-arrow__top-line {
    top: 11px;
    left: 11px;
  }

  .next .btn-arrow__bot-line {
    bottom: 11px;
    left: 11px;
  }
}

@media (max-width: 520px) {
  .body__btns {
    left: 8px;
    right: 8px;
  }
}