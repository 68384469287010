.canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  z-index: 0;

  background: transparent;
}

.canvas__bagels {
  margin-top: 220px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  opacity: 0;

  transition: all 0.25s ease-in-out 0s;
}

.canvas__bagels.show {
  opacity: 1;
}

@media (max-width: 1280px) and (min-width: 769px) {
  .courses .canvas__bagels {
    margin-top: 180px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .courses .canvas__bagels {
    margin-top: 160px;
  }
}

@media (max-width: 768px) {
  .courses .canvas__bagels {
    margin: 0;
  }
}

@media (max-width: 390px) {
  .courses .canvas__bagels {
    margin-top: 40px;
  }
}

.spinner-border.text-primary.canvas-spinner {
  position: absolute;
  left: 47vw;
  right: 0;
  top: 55%;
  bottom: 0;

  height: 6vw;
  width: 6vw;

  color: var(--deep-blue) !important;
  opacity: 0;

  transition: all 0.25s ease-in-out 0s;
}

.spinner-border.text-primary.canvas-spinner.show {
  opacity: 1;
}

@media (max-width: 768px) {
  .spinner-border.text-primary.canvas-spinner {
    left: 45vw;
    top: 45%;

    height: 10vw;
    width: 10vw;
  }
}

@media (max-width: 390px) {
  .spinner-border.text-primary.canvas-spinner {
    top: 50%;

    height: 12vw;
    width: 12vw;
  }
}