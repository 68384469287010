.body__cards-wrap {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.body__cards {
  margin: 0;
  margin-right: -1%;
  padding: 0;

  width: auto;
  height: auto;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  transition: all 0.5s linear 0s;
}

.body__card {
  position: relative;

  margin: 0;
  margin-right: 1%;
  padding-top: 34.119%;

  flex: 0 0 24%;

  display: flex;
  flex-flow: column;
  align-items: center;

  border-radius: 28px;

  color: var(--team-card-font-color);
}

.body__card:last-child {
  margin: 0;
}

.card__badge {
  position: absolute;
  bottom: 11%;

  padding: 18px 10.383%;

  width: 92.172%;
  min-height: 92px;

  border-radius: 30px;

  background-color: var(--team-badge-bg-color);
}

@media (max-width: 1280px) {
  .body__card {
    flex: 0 0 32.334%;
  }

  .card__badge {
    bottom: 5%;

    padding: 8px 10.383%;

    min-height: 72px;
  }
}

@media (max-width: 992px) {
  .card__badge {
    bottom: 5%;

    padding: 8px 12px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    min-height: 48px;
  }
}

@media (max-width: 768px) {
  .body__cards {
    margin-right: -2%;
  }

  .body__card {
    margin-right: 2%;
    padding-top: 0;

    flex: 0 0 48%;
    height: 400px;
  }

  .card__badge {
    position: absolute;
    bottom: 4%;

    padding: 15px 9.5%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;

    width: 92%;
    min-height: 86px;
  }
}

@media (max-width: 520px) {
  .body__cards {
    margin-right: -1%;
  }

  .body__card {
    margin-right: 1%;

    flex: 0 0 99%;
    height: 556px;
  }
}