/* home hero */
.hero__colored-title {
  margin-bottom: 4.168%;

  max-width: 1136px;
  font-family: var(--machina-bold);
  font-size: 70px;
  font-weight: 800;
  line-height: 0.914;
  text-transform: uppercase;
  text-align: center;

  user-select: none;
}

.hero__colored-text {
  color: var(--hero-title-accent-color);
}

@media (max-width: 1280px) {
  .hero__colored-title {
    font-size: 56px;
  }
}

@media (max-width: 1024px) {
  .hero__colored-title {
    font-size: 48px;

    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .hero__colored-title {
    font-size: 42px;

    margin-bottom: 42px;
  }
}

@media (max-width: 640px) {
  .hero__colored-title {
    font-size: 34px;
  }
}

@media (max-width: 480px) {
  .hero__colored-title {
    font-size: 28px;
    text-align: start;
  }
}

/* courses hero */
.courses .content__colored-title {
  margin: 0 auto;
  margin-bottom: 30.4%;

  max-width: 1296px;

  font-family: var(--machina-bold);
  font-size: 70px;
  font-weight: 800;
  line-height: 0.914;
  text-align: center;

  z-index: 2;
}

.courses .content__colored-text {
  color: var(--corses-hero-innertext-color);
}

@media (max-width: 1280px) and (min-width: 769px) {
  .courses .content__colored-title {
    max-width: 960px;

    font-size: 52px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .courses .content__colored-title {
    max-width: 800px;

    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .courses .content__colored-title {
    margin-bottom: 340px;

    width: 100%;

    font-size: 28px;
    line-height: 1.071;
    text-align: left;
  }
}

/* company hero */
.company .content__colored-title,
.company .content__colored-text {
  margin-top: 8.460%;
  margin-bottom: 3.022%;

  font-family: var(--machina-bold);
  font-size: 70px;
  font-weight: 800;
  line-height: 1;
}

.company .content__colored-text {
  color: var(--company-hero-colored-font-color);
}

@media (max-width: 1919px) and (min-width: 769px) {

  .company .content__colored-title,
  .company .content__colored-text {
    font-size: 58px;
  }
}

@media (max-width: 1600px) and (min-width: 769px) {

  .company .content__colored-title,
  .company .content__colored-text {
    font-size: 48px;
  }
}

@media (max-width: 1400px) and (min-width: 769px) {

  .company .content__colored-title,
  .company .content__colored-text {
    font-size: 40px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {

  .company .content__colored-title,
  .company .content__colored-text {
    font-size: 30px;
  }
}

@media (max-width: 768px) {

  .company .content__colored-title,
  .company .content__colored-text {
    margin-top: 42px;
    margin-bottom: 18px;

    font-size: 32px;
    line-height: 1.09375;
  }

  .company .content__colored-text {
    margin: 0;
    display: block;
  }
}

/* company team */
.company__team .team__colored-title,
.company__team .team__colored-text {
  margin-bottom: 1.9%;

  max-width: 1056px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
  text-align: center;

  color: var(--company-team-font-color);
}

.company__team .team__colored-text {
  display: block;
  color: var(--company-team-accent-color);
}

@media (max-width: 1400px) and (min-width: 769px) {

  .company__team .team__colored-title,
  .company__team .team__colored-text {
    font-size: 40px;
  }
}

@media (max-width: 1280px) and (min-width: 769px) {

  .company__team .team__colored-title,
  .company__team .team__colored-text {
    font-size: 36px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {

  .company__team .team__colored-title,
  .company__team .team__colored-text {
    max-width: 680px;
    font-size: 28px;
  }
}

@media (max-width: 768px) {

  .company__team .team__colored-title,
  .company__team .team__colored-text {
    font-size: 28px;
    line-height: 1.071;
  }
}