.projects__items {
  margin: 0;
  margin-bottom: 40px;
  padding: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
  height: auto;
}

.projects__item.item {
  flex: 0 0 48.206%;
  height: 22.4vw;
}

@media (max-width: 768px) {
  .projects__items {
    margin-bottom: 0;
  }

  .projects__item.item {
    flex: 0 0 100%;

    margin-top: -22px;

    min-height: 564px;
  }
}