.contacts__items {
  margin: 0;
  padding: 0;

  margin-top: 174px;

  display: flex;
  flex-flow: column nowrap;
}

.contacts__item {
  margin-bottom: 96px;

  opacity: 0;
}

.contacts__item:last-child {
  margin-bottom: 0;
}

.contacts__item-title {
  margin-bottom: 8px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;

  color: var(--menu-contact-title-color);
  opacity: 0.5;

  user-select: none;
}

@media (max-width: 1440px) {
  .contacts__items {
    margin-top: 132px;
  }
}

@media (max-width: 1280px) {
  .contacts__items {
    margin-top: 116px;
  }

  .contacts__item {
    margin-bottom: 52px;
  }
}

@media (max-width: 1024px) {
  .contacts__items {
    margin-top: 92px;
  }
}

@media (max-width: 960px) {
  .contacts__items {
    margin-top: 72px;
  }
}

@media (max-width: 864px) and (min-width: 768px) {
  .contacts__items {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .menu__contacts {
    grid-column: 1 / 2;
    grid-row: 2 / 5;
  }

  .contacts__items {
    margin-top: 0;
  }

  .contacts__item {
    margin-bottom: 16px;
  }
}