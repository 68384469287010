@keyframes tagAnimation {
  0% {
    -webkit-box-shadow: inset 15em 0 0 0 var(--hero-tag-accent-color);
    box-shadow: inset 15em 0 0 0 var(--hero-tag-accent-color);
  }

  50% {
    -webkit-box-shadow: inset 0 0 0 0 var(--hero-tag-color);
    box-shadow: inset 0 0 0 0 var(--hero-tag-color);
  }

  100% {
    -webkit-box-shadow: inset -15em 0 0 0 var(--hero-tag-accent-color);
    box-shadow: inset -15em 0 0 0 var(--hero-tag-accent-color);
  }
}

.courses-card {
  position: relative;

  flex: 0 1 42.058%;

  padding: 2.6% 1.823% 14%;

  border-radius: 30px;
  background-color: var(--hero-courses-bg-color);

  user-select: none;
}

.courses-card__tags {
  margin: 0;
  padding: 0;

  margin-bottom: 13%;

  display: flex;
  flex-flow: row wrap;

  max-width: 400px;
}

.courses-card__tag {
  padding: 8px 30px;

  margin-bottom: 10px;
  margin-right: 12px;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  text-transform: uppercase;

  background: transparent;
  border: 1px solid var(--hero-tag-color);
  border-radius: 24px;

  animation-name: tagAnimation;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@media (max-width: 768px) {
  .courses-card {
    flex: 0 1 100%;

    margin-bottom: 18px;
    padding: 28px 26px 142px;

    border-radius: 18px;
  }

  .courses-card__tag {
    margin-bottom: 7px;

    font-family: var(--montserrat);
  }
}