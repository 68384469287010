.courses .content__colored-text-box {
  margin-left: 3.386%;

  max-width: 396px;

  font-size: 18px;
  font-weight: 800;
  line-height: 1.333;
  letter-spacing: 0.8px;
}

.courses .content__colored-innertext {
  color: var(--corses-hero-innertext-color);
}

@media (max-width: 1280px) and (min-width: 769px) {
  .courses .content__colored-text-box {
    max-width: 340px;
    font-size: 15px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .courses .content__colored-text-box {
    max-width: 272px;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .courses .content__colored-text-box {
    margin: 0 3%;
    margin-bottom: 30px;

    max-width: 100%;

    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.7px;
  }
}