.logo {
  flex: 0 0 122px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 44px;
}

.logo svg {
  width: 122px;
  height: 42px;
}