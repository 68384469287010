.terms {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 176px 9.6875% 0;

  width: 100%;
  height: auto;

  color: var(--corses-font-color);
  border-color: var(--corses-bg-color);

  user-select: none;
}

.footer.black {
  background-color: var(--footer-bg-color);
}

.terms-section-title {
  margin-bottom: 24px;
  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
  text-align: center;
}

.terms-section-subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.556;
}

.terms-section-items {
  padding-left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.terms-section-item {
  margin-bottom: 20px;
}

.terms-section-item-title {
  margin-bottom: 20px;
  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.125;
}

.terms-section-item-subtitle {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.556;
}

.terms-section-item-subitems {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.terms-section-item-subitem {
  list-style: inside;
  font-size: 18px;
  line-height: 1.556;
}

.terms-section-item-p {
  font-size: 18px;
  line-height: 1.556;
}

@media (max-width: 768px) {
  .terms {
    padding: 148px 16px 0;
  }

  .terms-section-title {
    margin-bottom: 12px;
    font-size: 28px;
  }

  .terms-section-subtitle {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .terms-section-item {
    margin-bottom: 12px;
  }

  .terms-section-item-title {
    margin-bottom: 12px;
    font-size: 18px;
  }

  .terms-section-item-subtitle {
    font-size: 14px;
  }

  .terms-section-item-subitem {
    font-size: 14px;
  }

  .terms-section-item-p {
    font-size: 14px;
  }
}