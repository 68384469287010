.company__start .start__body {
  padding: 6.771% 39.540% 7.859% 8.464%;

  border-radius: 30px;
  background-color: var(--company-start-body-bg-color);
}

@media (max-width: 768px) {
  .company__start .start__body {
    padding: 94.886% 4.545% 5.113%;
  }
}