.company__why-mds .body__content-box {
  flex: 0 0 45.158%;

  padding: 48px 36px;

  border: 1px solid var(--company-why-mds-body-border-color);
  border-radius: 30px;
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .company__why-mds .body__content-box {
    padding: 24px;
  }
}

@media (max-width: 1024px) {
  .company__why-mds .body__content-box {
    margin-bottom: 26px;
  }
}

@media (max-width: 768px) {
  .company__why-mds .body__content-box {
    padding: 28px 24px 22px;
  }
}