.home__investors {
  width: 100%;

  display: grid;
  grid-template: auto / 42.284% 6.650% 51.067%;

  padding: 3.542% 9.271% 7% 7.709%;

  color: var(--investors-font-color);
  background-color: var(--investors-bg-color);

  user-select: none;
}

.investors__content {
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.investors__items {
  margin: 0;
  padding: 0;

  padding-left: 28px;
  margin-bottom: 5.342%;
}

.investors__item {
  position: relative;

  margin-bottom: 4.335%;

  font-size: 18px;
  line-height: 1.556;
}

.investors__item::before {
  content: '';

  position: absolute;
  top: 12px;
  left: -19px;

  width: 10px;
  height: 10px;

  border-radius: 5px;
  background-color: var(--investors-font-color);
}

.investors__img-box {
  grid-column: 3 / 4;
  grid-row: 1 / 2;

  width: 100%;
  height: 100%;
}

@media (max-width: 1280px) {
  .investors__item {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .investors__item {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .home__investors {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    padding: 12.308% 5.129%;
  }

  .investors__items {
    margin-bottom: 20px;
  }

  .investors__item {
    font-size: 14px;
    line-height: 1.714;
  }

  .investors__content {
    margin-bottom: 32px;
  }

  .investors__img-box {
    height: 432px;
  }
}