.works__arrow-btn.next,
.works__arrow-btn.prev {
  flex: 0 0 48.906%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  height: 75px;

  border: 1px solid var(--corses-arrow-line-color);
  border-radius: 20px;

  background: transparent;

  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.works__arrow-btn.next.disabled,
.works__arrow-btn.prev.disabled {
  background-color: var(--corses-arrow-bg-current-color);
  opacity: 0.3;
  cursor: default;
}

.works__arrow-btn:hover,
.works__arrow-btn:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-arrow-bg-current-color);
  box-shadow: inset 0 10em 0 0 var(--corses-arrow-bg-current-color);
}

.works__arrow-btn:active {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
}

.arrow-btn__top-line,
.arrow-btn__bot-line {
  display: block;

  width: 18px;
  height: 4px;

  background-color: var(--corses-arrow-line-color);
  border-radius: 1px;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);

  border-radius: 2px;
}

.prev .arrow-btn__top-line {
  transform: rotate(-45deg);
}

.prev .arrow-btn__bot-line {
  margin-top: 8px;
  transform: rotate(45deg);
}

.next .arrow-btn__top-line {
  transform: rotate(-135deg);
}

.next .arrow-btn__bot-line {
  margin-top: 8px;
  transform: rotate(135deg);
}

.reviews .item__arrow-btn.next,
.reviews .item__arrow-btn.prev {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 42px;
  height: 42px;

  border: 1px solid var(--corses-reviews-arrow-line-color);
  border-radius: 21px;

  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);

  z-index: 10;
}

.reviews .playing .item__arrow-btn.next,
.reviews .playing .item__arrow-btn.prev {
  opacity: 0.3;
  z-index: 10;
}

.reviews .item__arrow-btn.next.disabled,
.reviews .item__arrow-btn.prev.disabled {
  opacity: 0.5;
  cursor: default;
}

.reviews .playing .item__arrow-btn.next.disabled,
.reviews .playing .item__arrow-btn.prev.disabled {
  opacity: 0;
  cursor: default;
}

.reviews .item__arrow-btn.disabled:hover,
.reviews .item__arrow-btn.disabled:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-color);
  border: 1px solid var(--corses-reviews-arrow-line-color);
  opacity: 0.5;
  cursor: default;
}

.reviews .item__arrow-btn:hover,
.reviews .item__arrow-btn:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-current-color);
  box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-current-color);
  border: 1px solid var(--corses-reviews-arrow-bg-current-color);
}

.reviews .playing .item__arrow-btn:hover,
.reviews .playing .item__arrow-btn:focus {
  opacity: 1;
  cursor: pointer;
}

.reviews .item__arrow-btn:active,
.reviews .playing .item__arrow-btn:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-reviews-arrow-bg-color);
  border: 1px solid var(--corses-reviews-arrow-line-color);
  opacity: 1;
  cursor: pointer;
}

.reviews .arrow-btn__top-line,
.reviews .arrow-btn__bot-line {
  display: block;

  width: 12px;
  height: 2px;

  background-color: var(--corses-reviews-arrow-line-color);
  border-radius: 1px;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);

  border-radius: 2px;
}

.reviews .prev .arrow-btn__top-line {
  transform: rotate(-45deg);
}

.reviews .prev .arrow-btn__bot-line {
  margin-top: 6px;
  transform: rotate(45deg);
}

.reviews .next .arrow-btn__top-line {
  transform: rotate(-135deg);
}

.reviews .next .arrow-btn__bot-line {
  margin-top: 6px;
  transform: rotate(135deg);
}

.reviews .item__arrow-btn:hover .arrow-btn__top-line,
.reviews .item__arrow-btn:hover .arrow-btn__bot-line,
.reviews .item__arrow-btn:focus .arrow-btn__top-line,
.reviews .item__arrow-btn:focus .arrow-btn__bot-line {
  background-color: var(--corses-reviews-arrow-bg-color);
}

.reviews .item__arrow-btn.disabled:hover .arrow-btn__top-line,
.reviews .item__arrow-btn.disabled:hover .arrow-btn__bot-line,
.reviews .item__arrow-btn.disabled:focus .arrow-btn__top-line,
.reviews .item__arrow-btn.disabled:focus .arrow-btn__bot-line {
  background-color: var(--corses-reviews-arrow-line-color);
}

.reviews .item__arrow-btn:active .arrow-btn__top-line,
.reviews .item__arrow-btn:active .arrow-btn__bot-line {
  background-color: var(--corses-reviews-arrow-line-color);
}

/* company projects */
.btns__arrow-btn.next,
.btns__arrow-btn.prev {
  flex: 0 0 18.5%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  height: 75px;

  border: 1px solid var(--company-projects-btn-bg-color);
  border-radius: 24px;

  background: transparent;

  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.btns__arrow-btn.next.disabled,
.btns__arrow-btn.prev.disabled {
  background-color: var(--corses-arrow-bg-current-color);
  opacity: 0.3;
  cursor: default;
}

.btns__arrow-btn:hover,
.btns__arrow-btn:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-arrow-bg-current-color);
  box-shadow: inset 0 10em 0 0 var(--corses-arrow-bg-current-color);
}

.btns__arrow-btn:active {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
}