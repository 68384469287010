.why-mds__body.body {
  margin-bottom: 12px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
  height: auto;
}

.company__why-mds .body__items {
  margin: 0;
  padding: 0;

  margin-bottom: 34px;
}

.company__why-mds .body__item {
  position: relative;

  margin-left: 34px;
  margin-bottom: 28px;
}

.company__why-mds .body__item:last-child {
  margin-bottom: 0;
}

.company__why-mds .body__item::before {
  content: "";

  position: absolute;
  top: 10px;
  left: -18px;

  width: 10px;
  height: 10px;

  border-radius: 5px;
  background-color: var(--company-why-mds-font-color);
}

@media (max-width: 1024px) {
  .why-mds__body.body {
    flex-flow: column nowrap;
  }
}

@media (max-width: 768px) {
  .why-mds__body.body {
    margin-bottom: 6px;
  }

  .company__why-mds .body__items {
    margin-bottom: 28px;
  }

  .company__why-mds .body__item {
    margin-left: 21px;
    margin-bottom: 24px;

    font-size: 14px;
    line-height: 1.714;
  }

  .company__why-mds .body__item::before {
    left: -16px;

    width: 8px;
    height: 8px;

    border-radius: 4px;
  }
}