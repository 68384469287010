.possibilities__btns {
  margin: 0;
  padding: 0;

  margin-bottom: 32px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 88.229%;
}

.possibilities__btn-box {
  flex: 0 0 32.692%;
  max-width: 474px;
  height: auto;
}

@media (min-width: 1920px) {
  .possibilities__btns {
    max-width: 1460px
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .possibilities__btns {
    width: 100%;
  }
}