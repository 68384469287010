.find__cards {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 6.875vw;
}

@media (max-width: 768px) {
  .find__cards {
    grid-template: repeat(3, auto) / 1fr;
    gap: 48px;
  }
}

.find__card__link {
  padding: 1.666vw 2.5vw;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  height: 100%;

  border-radius: 1.562vw;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}

/* .find__card:first-child {
  background-color: #4638FF;
  border: 1px solid #4638FF;
} */

@media (max-width: 768px) {
  .find__card__link {
    padding: 24px;
    border-radius: 20px;
    max-width: 350px;
  }
}

@media (max-width: 390px) {
  .find__card__link {
    padding: 8.205vw 6.153vw;
    border-radius: 5.128vw;
  }
}

.find__card-top,
.find__card-mid,
.find__card-bot {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
}

.find__card-mid {
  flex: 1 1 auto;
}

.card__level-title {
  margin-bottom: 0.833vw;

  font-family: var(--machina-bold);
  font-weight: 800;
  font-size: 2.5vw;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 768px) {
  .card__level-title {
    margin-bottom: 8px;
    font-size: 24px;
  }
}

@media (max-width: 390px) {
  .card__level-title {
    margin-bottom: 2.051vw;
    font-size: 6.153vw;
  }
}

.card__level-name {
  margin-bottom: 1.0416vw;

  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.61;
}

@media (max-width: 768px) {
  .card__level-name {
    margin-bottom: 12px;
    font-size: 18px;
  }
}

@media (max-width: 390px) {
  .card__level-name {
    margin-bottom: 3.076vw;
    font-size: 4.615vw;
  }
}

.card__level-time {
  padding: 0.572vw 0.729vw;
  margin-bottom: 1.666vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 1.041vw;
}

.card__level-time-icon {
  margin-right: 0.468vw;
  width: 0.833vw;
  height: 0.833vw;
}

.card__level-time-text {
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 1.2;
}

.card__level-time-text.bold {
  margin-left: 0.208vw;
  font-weight: 800;
}

@media (max-width: 768px) {
  .card__level-time {
    padding: 10px 14px;
    margin-bottom: 24px;
    border-radius: 20px;
  }

  .card__level-time-text {
    font-size: 12px;
  }

  .card__level-time-icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 390px) {
  .card__level-time {
    padding: 2.564vw 3.589vw;
    margin-bottom: 6.153vw;
  }

  .card__level-time-text {
    font-size: 3.076vw;
  }

  .card__level-time-icon {
    margin-right: 2.051vw;
    width: 4.102vw;
    height: 4.102vw;
  }
}

.card__level-line {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}

.card__level-tags-title {
  margin: 0;
  margin-top: 2.5vw;
  margin-bottom: 2.083vw !important;

  width: 100%;

  margin-bottom: 0.833vw;

  font-family: var(--machina-bold);
  font-weight: 800;
  font-size: 1.25vw;
  line-height: 1.2;
  text-align: start;
}


@media (max-width: 768px) {
  .card__level-tags-title {
    margin-top: 32px;
    margin-bottom: 24px !important;
    font-size: 18px;
  }
}

.card__level-tags {
  margin: 0;
  margin-bottom: 2.5vw;
  padding: 0;

  display: grid;
  grid-template: 1fr / 1fr;
  gap: 8px;

  width: 100%;
}

@media (max-width: 768px) {
  .card__level-tags {
    margin-bottom: 32px;
  }
}

.card__level-tag {
  padding: 0.5208vw 1.667vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 1vw;

  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 768px) {
  .card__level-tag {
    padding: 12px 16px;
    font-size: 12px;
    border-radius: 20px;
  }
}

.card__level-price-title {
  margin-top: 1.666vw;
  margin-bottom: 0.833vw;

  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 768px) {
  .card__level-price-title {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 18px;
  }
}

@media (max-width: 390px) {
  .card__level-price-title {
    margin-top: 6.153vw;
    margin-bottom: 2.051vw;
    font-size: 4.615vw;
  }
}

.card__level-price-number {
  margin-bottom: 2.5vw;

  font-family: var(--machina-bold);
  font-weight: 800;
  font-size: 2.5vw;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 768px) {
  .card__level-price-number {
    margin-bottom: 42px;
    font-size: 24px;
  }
}

@media (max-width: 390px) {
  .card__level-price-number {
    margin-bottom: 10.769vw;
    font-size: 6.153vw;
  }
}

.find__card__btn-link {
  padding: 1.458vw 1.041vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3.958vw;

  font-family: var(--machina-bold);
  font-size: 1.25vw;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--corses-hero-btn-bg-color);
  border-radius: 1.25vw;

  color: var(--corses-hero-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

/* .find__card__btn-link:hover,
.find__card__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-hero-btn-font-hover-color);
}

.find__card__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  color: var(--corses-hero-btn-font-color);
} */

@media (max-width: 768px) {
  .find__card__btn-link {
    margin: 0;
    padding: 32px 0;

    width: 100%;
    height: 60px;

    font-size: 16px;
    line-height: 1.75;
    text-align: center;
    border-radius: 20px;
  }
}

@media (max-width: 390px) {
  .find__card__btn-link {
    margin: 0;
    padding: 8.205vw 0;

    width: 100%;
    height: 15.384vw;

    font-size: 4.102vw;
    line-height: 1.75;
    text-align: center;
  }
}

/* .find__card:not(:first-child) .find__card__btn-link {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-hero-btn-font-hover-color);
}

.find__card:not(:first-child) .find__card__btn-link:hover,
.find__card:not(:first-child) .find__card__btn-link:focus {
  color: var(--corses-hero-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
}

.find__card:not(:first-child) .find__card__btn-link:active {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-hero-btn-font-hover-color);
} */

.find__card__link .find__card__btn-link {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-hero-btn-font-hover-color);
}

.find__card__link .find__card__btn-link:hover,
.find__card__link .find__card__btn-link:focus {
  color: var(--corses-hero-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
}

.find__card__link .find__card__btn-link:active {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-hero-btn-font-hover-color);
}

.card__level-days {
  margin: 0;
  padding: 0;
  margin-top: 1.666vw;
  margin-bottom: 2.187vw;

  display: grid;
  grid-template: 1fr / 1fr;
  gap: 1.666vw;
}

.card__level-day {
  display: flex;
  flex-flow: column;
}

.card__level-day-title {
  margin: 0;
  margin-bottom: 0.520vw;
  font-family: var(--machina-regulag);
  font-weight: 500;
  font-size: 0.729vw;
  line-height: 1.4;
  color: #888a8c;
}

.current .card__level-day-title {
  color: #ff5c00;
}

.card__level-day-text {
  margin: 0;
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 0.937vw;
  line-height: 1.55;
  color: #ffffff;
}

@media (max-width: 768px) {
  .card__level-days {
    margin-top: 32px;
    margin-bottom: 32px;

    gap: 32px;
  }

  .card__level-day-title {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .card__level-day-text {
    font-size: 15px;
  }
}

@media (max-width: 390px) {
  .card__level-days {
    margin-top: 8.205vw;
    margin-bottom: 8.205vw;

    gap: 8.205vw;
  }

  .card__level-day-title {
    margin-bottom: 2.564vw;
    font-size: 3.076vw;
  }

  .card__level-day-text {
    font-size: 3.846vw;
  }
}