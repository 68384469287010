.start__accordion .accordion-card__arrows {
  position: absolute;
  bottom: 7px;
  left: 9px;
  right: 9px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

}

.start__accordion .accordion-card__btn-arrow {
  position: relative;

  width: 42px;
  height: 42px;

  background: transparent;

  border: 1px solid var(--btn-arrow-border-color);
  border-radius: 21px;

  -webkit-box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);
  box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

/* disabled  */
.start__accordion .accordion-card__btn-arrow:disabled {
  cursor: default;
  border-color: var(--btn-arrow-line-disabled-color);
  pointer-events: none;
}

.start__accordion .accordion-card__btn-arrow:disabled .btn-arrow__top-line,
.start__accordion .accordion-card__btn-arrow:disabled .btn-arrow__bot-line {
  background-color: var(--btn-arrow-line-disabled-color);
  pointer-events: none;
}

/* hover / focus  */
.start__accordion .accordion-card__btn-arrow:hover,
.start__accordion .accordion-card__btn-arrow:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--btn-arrow-bg-hover-color);
  box-shadow: inset 0 10em 0 0 var(--btn-arrow-bg-hover-color);
  border-color: var(--btn-arrow-border-hover-color);
}

.start__accordion .accordion-card__btn-arrow:hover .btn-arrow__top-line,
.start__accordion .accordion-card__btn-arrow:hover .btn-arrow__bot-line,
.start__accordion .accordion-card__btn-arrow:focus .btn-arrow__top-line,
.start__accordion .accordion-card__btn-arrow:focus .btn-arrow__bot-line {
  background-color: var(--btn-arrow-line-hover-color);
}

/* active */
.start__accordion .accordion-card__btn-arrow:active {
  -webkit-box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);
  box-shadow: inset 0 0 0 0 var(--btn-arrow-bg-hover-color);
  border-color: var(--btn-arrow-border-color);
}

.start__accordion .accordion-card__btn-arrow:active .btn-arrow__top-line,
.start__accordion .accordion-card__btn-arrow:active .btn-arrow__bot-line {
  background-color: var(--btn-arrow-line-color);
}

.start__accordion .btn-arrow__top-line,
.start__accordion .btn-arrow__bot-line {
  position: absolute;
  left: 0;

  display: block;

  width: 10px;
  height: 2px;

  background-color: var(--btn-arrow-line-color);
  border-radius: 1px;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.start__accordion .prevs .btn-arrow__top-line {
  top: 16px;
  left: 14.5px;
  transform: rotate(-45deg);
}

.start__accordion .prevs .btn-arrow__bot-line {
  bottom: 16px;
  left: 14.5px;
  transform: rotate(45deg);
}

.start__accordion .next .btn-arrow__top-line {
  top: 16px;
  left: 15.5px;
  transform: rotate(-135deg);
}

.start__accordion .next .btn-arrow__bot-line {
  bottom: 16px;
  left: 15.5px;
  transform: rotate(135deg);
}