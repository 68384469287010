/* home start */
.start__btn,
.item-courses__btn,
.item-startup__btn,
.form__btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 56px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 4;

  background: transparent;

  border: 1px solid var(--start-btn-border-color);
  border-radius: 50px;

  -webkit-box-shadow: inset 0 0 0 0 var(--start-btn-hover-bg-color);
  box-shadow: inset 0 0 0 0 var(--start-btn-hover-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.start__btn:hover,
.item-courses__btn:hover,
.item-startup__btn:hover,
.start__btn:focus,
.item-courses__btn:focus,
.item-startup__btn:focus,
.start__btn-box.current .start__btn,
.item-courses.current .item-courses__btn,
.item-startup.current .item-startup__btn {
  -webkit-box-shadow: inset 0 10em 0 0 var(--start-btn-hover-bg-color);
  box-shadow: inset 0 10em 0 0 var(--start-btn-hover-bg-color);
  border-color: var(--start-btn-hover-border-color);
  color: var(--start-btn-hover-font-color);
}

.start__btn:active,
.item-courses__btn:active,
.item-startup__btn:active {
  -webkit-box-shadow: inset 0 0 0 0 var(--start-btn-hover-bg-color);
  box-shadow: inset 0 0 0 0 var(--start-btn-hover-bg-color);
  border-color: var(--start-btn-border-color);
  color: inherit;
}

@media (max-width: 1280px) {
  .start__btn {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .start__btn {
    font-size: 18px;
  }
}

@media (max-width: 768px) {

  .item-courses__btn,
  .item-startup__btn {
    width: 93.583%;

    font-size: 16px;
    line-height: 6;
  }
}

/* contact card */
.home__why-mds .form__btn,
.courses__curator .form__btn,
.company__why-mds .form__btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 36.023%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  color: var(--contact-card-btn-font-color);

  background: transparent;

  border: 1px solid var(--contact-card-btn-border-color);
  border-radius: 24px;

  -webkit-box-shadow: inset 0 10em 0 0 var(--contact-card-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--contact-card-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.home__why-mds .form__btn:hover,
.home__why-mds .form__btn:focus,
.courses__curator .form__btn:hover,
.courses__curator .form__btn:focus,
.company__why-mds .form__btn:hover,
.company__why-mds .form__btn:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--contact-card-btn-font-hover-color);
}

.home__why-mds .form__btn:active,
.courses__curator .form__btn:active,
.company__why-mds .form__btn:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--contact-card-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--contact-card-btn-bg-color);
  color: var(--contact-card-btn-font-color);
}

@media (max-width: 1280px) and (min-width: 1025px) {

  .home__why-mds .form__btn,
  .courses__curator .form__btn,
  .company__why-mds .form__btn {
    width: 35%;
    height: 68px;

    font-size: 20px;
  }
}

@media (max-width: 1024px) {

  .home__why-mds .form__btn,
  .courses__curator .form__btn,
  .company__why-mds .form__btn {
    grid-row: 2 / 3;
    grid-column: 1 / 3;

    margin-top: 40px;

    width: 100%;
  }
}

@media (max-width: 768px) {

  .home__why-mds .form__btn,
  .courses__curator .form__btn,
  .company__why-mds .form__btn {
    width: 100%;
    height: 60px;

    font-size: 16px;
    line-height: 6;
  }
}

/* contact form */
.contact__form .form__btn {
  margin-bottom: 38px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  color: var(--contact-form-btn-font-color);

  background: transparent;

  border: 1px solid var(--contact-form-btn-border-color);
  border-radius: 24px;

  -webkit-box-shadow: inset 0 10em 0 0 var(--contact-form-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--contact-form-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.contact__form .form__btn:hover,
.contact__form .form__btn:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--contact-form-btn-font-hover-color);
}

.contact__form .form__btn:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--contact-form-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--contact-form-btn-bg-color);
  color: var(--contact-form-btn-font-color);
}

@media (max-width: 768px) {
  .contact__form .form__btn {
    margin-bottom: 30px;

    height: 60px;

    font-size: 16px;
    line-height: 1.75;
  }
}

/* company possibilities*/
.possibilities__btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 56px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1;
  color: var(--company-possibilities-font-color);

  background: transparent;

  border: 1px solid var(--company-possibilities-btn-border-color);
  border-radius: 24px;

  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.possibilities__btn:hover,
.possibilities__btn:focus,
.current .possibilities__btn {
  -webkit-box-shadow: inset 0 10em 0 0 var(--company-possibilities-accent-color);
  box-shadow: inset 0 10em 0 0 var(--company-possibilities-accent-color);
  color: var(--company-possibilities-btn-font-hover-color);
  border-color: var(--company-possibilities-accent-color);
}

.possibilities__btn:active {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--company-possibilities-font-color);
  border-color: var(--company-possibilities-btn-border-color);
}

@media (max-width: 1024px) and (min-width: 769px) {
  .possibilities__btn {
    font-size: 20px;
  }
}