.nav {
  margin-top: 126px;
}

.nav__items {
  margin: 0;
  padding: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.nav__item {
  margin-bottom: 32px;

  opacity: 0;
}

.nav__item:last-child {
  margin-bottom: 0;
}

@media (max-width: 1440px) {
  .nav {
    margin-top: 116px;
  }
}

@media (max-width: 1280px) {
  .nav {
    margin-top: 96px;
  }
}

@media (max-width: 1024px) {
  .nav {
    margin-top: 84px;
  }
}

@media (max-width: 960px) {
  .nav {
    margin-top: 64px;
  }
}

@media (max-width: 768px) {
  .nav {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    margin-top: 126px;
    margin-bottom: 84px !important;
  }

  .nav__item {
    margin-bottom: 24px;
  }
}