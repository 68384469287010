.start__accordion .accordion-card {
  position: relative;

  padding-bottom: 69px;

  width: 100%;
  height: 100%;

  border: 1px solid var(--start-card-border-color);
  border-radius: 30px;

  overflow: hidden;

  transition: all 0.5s linear 0s;
}

.start__accordion .accordion-card__img {
  margin: 24px 22px 21px;

  height: 100px;
  width: auto;
}

.accordion-card__text.spec {
  margin-bottom: 10px;
}

.accordion-card__text.li {
  list-style: inside;
  margin-bottom: 4px;
}

.accordion-card__text.li:last-child {
  margin-bottom: 0;
}

.accordion-card ul {
  padding-left: 0;
}