.start__cards {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 24px;
}

@media (max-width: 1280px) {
  .start__cards {
    grid-template: 1fr / repeat(2, 1fr);
  }
}