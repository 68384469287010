.courses__curator.curator {
  padding: 74px 6.875% 82px;

  width: 100%;
  height: auto;

  color: var(--corses-curator-font-color);
  background: var(--corses-curator-bg-color);
}

.courses__curator.curator {
  padding-top: 0 !important;
}

.curator__body.body {
  margin-bottom: 74px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.body__about.about {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
}

@media (max-width: 1440px) and (min-width: 769px) {
  .curator__body.body {
    display: grid;
    grid-template: 1fr 1fr / minmax(424px, auto) 6.160% auto;
  }

  .body__about.about {
    margin-bottom: auto;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .curator__body.body {
    grid-template: 1fr 1fr / 55% 5% 40%;
  }
}

@media (max-width: 768px) {
  .courses__curator.curator {
    padding: 14px 4.103% 48px;
  }

  .curator__body.body {
    margin-bottom: 64px;
    flex-flow: column nowrap;
  }

  .body__about.about {
    width: auto;
    margin: 0 auto;
  }
}

@media (max-width: 520px) {
  .body__about.about {
    margin-left: 4px;
    width: 100%;
  }
}