.courses .courses__hero.hero {
  position: relative;

  padding-top: 116px;

  width: 100%;
  height: auto;

  background: url(./courses-hero-bg.webp) center / cover no-repeat;
}

@media (max-width: 768px) {
  .courses .courses__hero.hero {
    padding-top: 128px;

    background-position: top left;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .courses .hero__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: white;

    z-index: -1;
  }
}