@import url(./reset.css);

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: 'Coanda';
  src: url('../fonts/Coanda-normal-700-100.otf');
}

@font-face {
  font-family: 'Neue-Machina-Light';
  src: url('../fonts/NeueMachina-Light.otf');
}

@font-face {
  font-family: 'Neue-Machina-Regular';
  src: url('../fonts/NeueMachina-Regular.otf');
}

@font-face {
  font-family: 'Neue-Machina-Ultrabold';
  src: url('../fonts/NeueMachina-Ultrabold.otf');
}

:root {
  /* fonts */
  --montserrat: 'Montserrat', sans-serif;
  --coanda: 'Coanda';
  --machina-light: 'Neue-Machina-Light';
  --machina-regulag: 'Neue-Machina-Regular';
  --machina-bold: 'Neue-Machina-Ultrabold';

  /* animation */
  --link-animation: all 0.2s ease 0s;
  --close-animation: all ease 0.5s;
  --btn-animation: all ease 0.7s;

  /* color palette */
  /* primary colors */
  --safety-orange: #FF5C00;
  --deep-blue: #4638FF;

  /* secondary colors */
  --tomato: #FF5757;
  --lime: #ABE32D;

  /* rgb colors */
  --dark: #000000;
  --black: #121619;
  --title-black: #020111;
  --light-gray: #D9D9D9;
  --gray: #EAEAEA;
  --white: #FFFFFF;

  /* body colors */
  --body-bg-color: var(--black);
  --body-font-color: var(--dark);

  /* header and menu colors */
  --btn-and-link-font-color: var(--white);
  --btn-and-link-border-color: var(--white);

  --header-lang-btn-hover-bg-color: var(--deep-blue);

  --menu-bg-color: var(--black);
  --menu-font-color: var(--white);
  --menu-msgr-link-hover-font-color: var(--black);
  --menu-msgr-link-hover-bg-color: var(--white);
  --menu-btn-hover-bg-color: var(--safety-orange);
  --menu-contact-title-color: var(--light-gray);
  --menu-contact-link-hover-color: var(--deep-blue);
  --menu-nav-link-hover-color: var(--safety-orange);

  /* footer colors  */
  --footer-bg-color: var(--black);
  --footer-subbg-color: var(--white);
  --footer-font-color: var(--white);
  --footer-font-hover-color: var(--deep-blue);

  /* home */
  --home-bg-color: var(--black);

  /* hero */
  --hero-bg-color: var(--white);
  --hero-font-color: var(--white);
  --hero-title-accent-color: var(--safety-orange);
  --hero-tag-color: var(--white);
  --hero-tag-accent-color: var(--safety-orange);

  --hero-courses-bg-color: var(--deep-blue);
  --hero-courses-btn-bg-color: var(--white);
  --hero-courses-btn-font-color: var(--black);
  --hero-courses-btn-hover-font-color: var(--white);
  --hero-courses-arrow--color: var(--lime);

  --hero-company-bg-color: var(--gray);
  --hero-company-btn-bg-color: var(--black);
  --hero-company-btn-font-color: var(--white);
  --hero-company-btn-hover-font-color: var(--black);
  --hero-company-title-font-color: var(--title-black);

  /* start */
  --start-bg-color: var(--white);
  --start-btn-border-color: var(--black);
  --start-btn-hover-border-color: var(--safety-orange);
  --start-btn-hover-bg-color: var(--safety-orange);
  --start-btn-hover-font-color: var(--white);
  --start-card-border-color: var(--light-gray);

  --btn-arrow-line-color: var(--black);
  --btn-arrow-border-color: var(--black);
  --btn-arrow-line-hover-color: var(--white);
  --btn-arrow-border-hover-color: var(--safety-orange);
  --btn-arrow-bg-hover-color: var(--safety-orange);
  --btn-arrow-line-disabled-color: var(--light-gray);

  /* why mds */
  --why-mds-font-color: var(--white);
  --why-mds-play-btn-border-color: var(--white);
  --why-mds-play-btn-bg-color: rgba(16, 16, 16, 0.57);
  --why-mds-play-btn-triangle-color: var(--white);
  --why-mds-play-btn-triangle-hover-color: var(--safety-orange);
  --why-mds-item-line-color: var(--white);

  /* investors */
  --investors-font-color: var(--dark);
  --investors-bg-color: var(--white);
  --investors-btn-bg-color: var(--deep-blue);
  --investors-btn-borer-color: var(--deep-blue);
  --investors-btn-font-color: var(--white);
  --investors-btn-hover-font-color: var(--deep-blue);

  /* team */
  --team-font-color: var(--dark);
  --team-card-font-color: var(--white);
  --team-bg-color: var(--white);
  --team-badge-bg-color: var(--safety-orange);

  /* faq */
  --faq-subbg-color: var(--white);
  --faq-bg-color: var(--black);
  --faq-font-color: var(--white);
  --faq-item-border-color: var(--white);
  --faq-item-body-bg-color: var(--white);
  --faq-item-body-font-color: var(--black);
  --faq-item-sidebar-bg-open-color: var(--safety-orange);

  /* sign up */
  --sign-up-bg-color: var(--white);
  --sign-up-btn-bg-color: var(--white);
  --sign-up-btn-border-color: var(--white);
  --sign-up-btn-font-color: var(--black);
  --sign-up-btn-hover-font-color: var(--white);

  /* contact card */
  --contact-card-bg-color: var(--deep-blue);
  --contact-card-font-color: var(--white);
  --contact-card-btn-font-color: var(--black);
  --contact-card-btn-font-hover-color: var(--white);
  --contact-card-btn-bg-color: var(--white);
  --contact-card-btn-border-color: var(--white);
  --contact-card-input-color: rgba(255, 255, 255, 0.30);
  --contact-card-input-line-color: rgba(255, 255, 255, 0.50);
  --contact-card-input-focus-color: var(--white);
  --contact-card-input-error-color: var(--tomato);
  --contact-card-popup-spinner-color: var(--deep-blue);
  --contact-card-popup-font-color: var(--deep-blue);
  --contact-card-popup-bg-color: var(--white);

  /* contact form */
  --contact-form-bg-color: var(--white);
  --contact-form-font-color: var(--black);
  --contact-form-border-color: var(--black);
  --contact-form-placeholder-color: var(--light-gray);
  --contact-form-btn-bg-color: var(--deep-blue);
  --contact-form-btn-border-color: var(--deep-blue);
  --contact-form-btn-font-color: var(--white);
  --contact-form-btn-font-hover-color: var(--deep-blue);
  --contact-form-checkbox-border-color: var(--black);
  --contact-form-checkbox-checked-color: var(--lime);
  --contact-form-error-color: var(--tomato);

  /* courses */
  --corses-font-color: var(--white);
  --corses-bg-color: var(--black);

  /* courses hero */
  --corses-hero-font-color: var(--white);
  --corses-hero-bg-color: var(--black);
  --corses-hero-innertext-color: var(--safety-orange);
  --corses-hero-btn-font-color: var(--black);
  --corses-hero-btn-font-hover-color: var(--white);
  --corses-hero-btn-bg-color: var(--white);
  --corses-hero-line-color: var(--white);

  /* courses suitable */
  --corses-suitable-font-color: var(--black);
  --corses-suitable-bg-color: var(--white);
  --corses-suitable-title-color: var(--dark);
  --corses-suitable-item-title-color: var(--deep-blue);
  --corses-suitable-line-color: var(--black);

  /* courses find */
  --corses-find-font-color: var(--white);
  --corses-find-card-line-color: var(--white);
  --corses-find-card-border-color: var(--white);
  --corses-find-card-current-bg-color: var(--deep-blue);
  --corses-find-card-current-border-color: var(--deep-blue);
  --corses-find-btn-bg-color: var(--white);
  --corses-find-btn-font-color: var(--dark);
  --corses-find-btn-border-color: var(--white);
  --corses-find-btn-hover-font-color: var(--white);
  --corses-find-arrow-color: var(--safety-orange);
  --corses-find-switcher-select-color: var(--light-gray);
  --corses-find-switcher-select-current-color: var(--lime);

  /* courses stages */
  --corses-stages-font-color: var(--dark);
  --corses-stages-bg-color: var(--white);
  --corses-stages-line-color: var(--dark);

  /* courses curator */
  --corses-curator-font-color: var(--black);
  --corses-curator-specialty-font-color: var(--deep-blue);
  --corses-curator-bg-color: var(--white);

  /* courses students */
  --corses-students-reviews-font-color: var(--white);
  --corses-curator-reviews-bg-color: var(--deep-blue);
  --corses-students-reviews-btn-font-color: var(--black);
  --corses-students-reviews-btn-font-hover-color: var(--white);
  --corses-students-reviews-btn-bg-color: var(--white);
  --corses-students-reviews-btn-botder-color: var(--white);

  --corses-reviews-arrow-line-color: var(--dark);
  --corses-reviews-arrow-bg-color: var(--white);
  --corses-reviews-arrow-bg-current-color: var(--safety-orange);
  --corses-reviews-arrow-bg-disabled-color: var(--light-gray);

  --corses-students-works-font-color: var(--black);
  --corses-students-works-font-mobole-color: var(--white);
  --corses-works-bg-color: var(--white);
  --corses-count-bar-color: rgba(217, 217, 217, 0.50);
  --corses-count-bar-current-color: var(--lime);
  --corses-arrow-line-color: var(--dark);
  --corses-arrow-bg-current-color: var(--light-gray);

  /* company */
  --company-font-color: var(--white);
  --company-bg-color: var(--black);

  /* company hero */
  --company-hero-bg-color: var(--white);
  --company-hero-font-color: var(--black);
  --company-hero-colored-font-color: var(--safety-orange);
  --company-hero-body-bg-color: var(--white);
  --company-hero-bot-line-color: var(--white);
  --company-hero-btn-bg-color: var(--deep-blue);
  --company-hero-btn-font-color: var(--white);
  --company-hero-btn-font-hover-color: var(--deep-blue);
  --company-hero-btn-border-color: var(--deep-blue);

  /* company possibilities */
  --company-possibilities-bg-color: var(--white);
  --company-possibilities-font-color: var(--black);
  --company-possibilities-accent-color: var(--safety-orange);
  --company-possibilities-btn-border-color: var(--black);
  --company-possibilities-btn-font-hover-color: var(--white);
  --company-possibilities-item-font-color: var(--dark);
  --company-possibilities-item-line-color: var(--light-gray);

  /* company why-mds */
  --company-why-mds-font-color: var(--white);
  --company-why-mds-accent-color: var(--deep-blue);
  --company-why-mds-body-border-color: var(--white);
  --company-why-mds-tag-border-color: var(--white);

  /* company start */
  --company-start-bg-color: var(--white);
  --company-start-font-color: var(--black);
  --company-start-accent-color: var(--deep-blue);
  --company-start-btn-font-color: var(--white);
  --company-start-body-bg-color: var(--light-gray);
  --company-start-btn-border-hover-color: var(--black);
  --company-start-btn-font-hover-color: var(--black);

  /* company team */
  --company-team-bg-color: var(--white);
  --company-team-font-color: var(--black);
  --company-team-accent-color: var(--deep-blue);

  /* company projects */
  --company-projects-container-bg-color: var(--white);
  --company-projects-font-color: var(--black);
  --company-projects-tag-line-color: var(--black);
  --company-projects-btn-bg-color: var(--black);
  --company-projects-btn-font-color: var(--white);
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  display: none;
}

* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;

  width: 100%;
  min-height: 100vh;

  font-family: var(--machina-regulag);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.556;
  color: var(--body-font-color);
  background-color: var(--body-bg-color);
}

body.scroll-locked {
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  min-height: 100vh;
}

#root>div {
  width: 100%;
  min-height: 100vh;
}