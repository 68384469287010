.company__team {
  padding: 3.542% 6.875%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;

  color: var(--team-font-color);
  background: var(--team-bg-color);

  user-select: none;
}

@media (max-width: 768px) {
  .company__team {
    padding: 52px 5.129% 20px;
  }
}

@media (max-width: 520px) {
  .company__team {
    padding: 13.847% 5.129% 5.129%;
  }
}