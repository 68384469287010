.courses__format.format {
  padding: 7.1875% 6.928% 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;

  color: var(--corses-suitable-font-color);
  background-color: var(--corses-suitable-bg-color);
}

.courses__format .format__title {
  margin: 0 auto 5.208%;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;

  color: var(--corses-suitable-title-color);
}

.courses__format .format__items {
  margin: 0 auto;
  padding: 0;

  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 3.125%;
}

@media (max-width: 1440px) and (min-width: 769px) {
  .courses__format .format__title {
    font-size: 40px;
  }
}

@media (max-width: 854px) and (min-width: 769px) {
  .courses__format .format__title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .courses__format.format {
    padding: 50px 5.128%;
  }

  .courses__format .format__title {
    margin-bottom: 48px;

    font-size: 28px;
    line-height: 1.071;
    text-align: center;
  }

  .courses__format .format__items {
    grid-template: repeat(3, auto) / 1fr;
    gap: 56px;
  }
}