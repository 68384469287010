.lang-select,
.lang-select__btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  height: 44px;
}

.lang-select {
  flex: 0 0 122px;

  justify-content: space-between;
}

.lang-select__btn {
  justify-content: center;

  width: 59px;

  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  line-height: 1.75;
  color: var(--btn-and-link-font-color);

  border: 1px solid var(--btn-and-link-border-color);
  border-radius: 24px;
  background-color: transparent;

  -webkit-box-shadow: inset 0 0 0 0 var(--header-lang-btn-hover-bg-color);
  box-shadow: inset 0 0 0 0 var(--header-lang-btn-hover-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.lang-select__btn:hover,
.lang-select__btn:focus,
.lang-select__btn.current {
  -webkit-box-shadow: inset 0 10em 0 0 var(--header-lang-btn-hover-bg-color);
  box-shadow: inset 0 10em 0 0 var(--header-lang-btn-hover-bg-color);
  border-color: var(--header-lang-btn-hover-bg-color);
}

.lang-select__btn:active {
  -webkit-box-shadow: inset 0 0 0 0 var(--header-lang-btn-hover-bg-color);
  box-shadow: inset 0 0 0 0 var(--header-lang-btn-hover-bg-color);
  border-color: var(--btn-and-link-border-color);
}

@media (max-width: 768px) {
  .menu__lang-select {
    opacity: 0;
  }
}