.courses__stages.stages {
  margin: 0;
  padding: 176px 6.875% 175px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;

  color: var(--corses-stages-font-color);
  background: var(--corses-stages-bg-color)
}

.stages .stages__items {
  margin: 0;
  padding: 0;

  padding-top: 76px;

  display: grid;
  grid-template: 1fr / repeat(4, 24.155%);
  grid-gap: 20px;

  width: 100%;

  border-top: 1px solid var(--corses-stages-line-color);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.stages .stages__item {
  position: relative;

  margin-top: 120px;
}

@media (max-width: 1280px) and (min-width: 641px) {
  .stages .stages__items {
    grid-template: 1fr / repeat(2, 47.585%);
    grid-gap: 40px;
  }
}

@media (max-width: 640px) {
  .courses__stages.stages {
    padding: 42px 20px 72px;
  }

  .stages .stages__items {
    grid-template: 1fr / 100%;

    padding-top: 30px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .stages .stages__item {
    position: relative;

    margin-top: 110px;
  }
}