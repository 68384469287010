.company-card {
  flex: 0 1 56.119%;

  display: flex;
  flex-flow: column nowrap;

  border-radius: 30px;
  background-color: var(--hero-company-bg-color);

  user-select: none;
}

@media (max-width: 768px) {
  .company-card {
    border-radius: 18px;
  }
}