.courses-card__text-arrow {
  position: relative;

  margin-bottom: 6px;

  display: inline-block;

  width: 134px;
  height: 2px;

  background-color: var(--hero-courses-arrow--color);
}

.courses-card__text-arrow::after,
.courses-card__text-arrow::before {
  content: '';

  position: absolute;
  right: -2px;

  height: 2px;
  width: 12px;
  background-color: var(--hero-courses-arrow--color);

  border-radius: 2px;
}

.courses-card__text-arrow::after {
  top: 4px;
  transform: rotate(-45deg);
}

.courses-card__text-arrow::before {
  bottom: 4px;
  transform: rotate(45deg);
}

@media (max-width: 1280px) {
  .courses-card__text-arrow {
    width: 100px;
  }
}

@media (max-width: 1024px) {
  .courses-card__text-arrow {
    width: 72px;
  }
}

@media (max-width: 768px) {
  .courses-card__text-arrow {
    width: 152px;
  }
}