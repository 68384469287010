.start__btns {
  margin: 0;
  padding: 0;

  margin-bottom: 32px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 59.690%;
  height: 56px;
}

.start__btn-box {
  flex: 0 1 460px;
}

.start__btn-box:first-child {
  margin-right: 4px;
}

@media (min-width: 1920px) {
  .start__btns {
    max-width: 932px;
  }
}