.courses__sign-up.sign-up {
  position: relative;

  padding: 9.375% 7.917% 9.375%;

  width: 100%;
  height: auto;

  background-color: var(--sign-up-bg-color);
}

.sign-up__container-btn {
  padding: 10.768% 20.793%;

  width: 100%;
  height: auto;

  border-radius: 42px;

  background: url(./sign-up-bg.webp) center / cover no-repeat;

  z-index: 1;
}

.sign-up__container-img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.sign-up__img {
  width: 35.730%;
  height: auto;

  z-index: 2;
}

@media (max-width: 1024px) {
  .sign-up__container-btn {
    padding: 10.768% 10.768%;
  }
}

@media (max-width: 768px) {
  .courses__sign-up.sign-up {
    padding: 50px 5.128% 50px;
  }

  .sign-up__container-btn {
    padding: 236px 5.143%;
  }

  .sign-up__container-img {
    top: 10px;
    width: 98%;
  }

  .sign-up__img {
    width: 100%;
    height: auto;
  }
}