.why-mds__content {
  display: grid;
  grid-template: auto auto / 34.904% 65.097%;
}

.content__items {
  grid-column: 2 / 3;
  grid-row: 2 / 3;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  margin: 0;
  padding: 0;

  margin-bottom: 2.969%;
}

@media (max-width: 1280px) {
  .why-mds__content {
    grid-template: auto auto / 40% 60%;
  }
}

@media (max-width: 1024px) {
  .why-mds__content {
    display: flex;
    flex-flow: column nowrap;

  }
}

@media (max-width: 768px) {
  .why-mds__content {
    width: 93.715%;
  }

  .content__items {
    margin-bottom: 20px;
  }
}