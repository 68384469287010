.works__item .item__count-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  margin: 18px 24px 0;
  padding: 0;

  display: grid;
  gap: 2px;

  width: auto;

  z-index: 15;
}

.works__item .item__count-item {
  flex: 0 0 100px;

  height: 5px;
  border-radius: 5px;
  background-color: var(--corses-count-bar-color);

  transition: all 0.25s ease-in-out 0s;
}

.head__count-item {
  width: auto;
  height: 5px;

  border-radius: 5px;
  background-color: var(--corses-count-bar-color);

  transition: all 0.25s ease-in-out 0s;
}

.works__item .item__count-item.current,
.head__count-item.current {
  background-color: var(--corses-count-bar-current-color);
}