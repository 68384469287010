.courses-card__arrow-title {
  margin-bottom: 6.5%;

  max-width: 564px;

  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.093;
}

@media (max-width: 1280px) {
  .courses-card__arrow-title {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .courses-card__arrow-title {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .courses-card__arrow-title {
    margin-bottom: 28px;

    font-size: 24px;
    line-height: 1.208;
  }
}