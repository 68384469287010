.works__tag,
.reviews__tag {
  margin-top: 6px;
  margin-left: 20px;
  padding: 8px 12px;

  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: center;

  border-radius: 24px;
}

.works__tag {
  border: 1px solid var(--corses-students-works-font-color);
}

.reviews__tag {
  border: 1px solid var(--corses-students-reviews-font-color);
}

@media (max-width: 1440px) and (min-width: 769px) {

  .works__tag,
  .reviews__tag {
    padding: 6px 8px;

    font-size: 13px;
  }
}

@media (max-width: 768px) {

  .works__tag,
  .reviews__tag {
    margin: 0;
    margin-bottom: 6px;
  }
}

/* company why-mds */
.company__why-mds .body__tag {
  margin-bottom: 32px;
  padding: 7px 12px;

  width: fit-content;
  min-width: 182px;

  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.2px;
  text-align: center;

  border: 1px solid var(--company-why-mds-tag-border-color);
  border-radius: 24px;
}

@media (max-width: 768px) {
  .company__why-mds .body__tag {
    margin-bottom: 20px;

    min-width: 140px;

    letter-spacing: auto;
  }
}

/* company projects */
.projects__head .head__tag {
  margin-top: 4px;
  padding: 6px 56px;

  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.1px;

  border: 1px solid var(--company-projects-tag-line-color);
  border-radius: 24px;
}

@media (max-width: 1280px) and (min-width: 769px) {
  .projects__head .head__tag {
    padding: 6px 20px;
  }
}

@media (max-width: 768px) {
  .projects__head .head__tag {
    margin-bottom: 20px;
  }
}