.menu .nav__link {
  display: block;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
  text-transform: uppercase;

  color: var(--menu-font-color);

  transition: all 0.3s ease-in-out 0s;
}

.menu .nav__link:hover,
.menu .nav__link:focus {
  transform: translate(52px, 0);
  color: var(--menu-nav-link-hover-color)
}

.menu .nav__link:active {
  transform: translate(52px, 0) scale(0.94);
  color: var(--menu-nav-link-hover-color)
}

@media (max-width: 1440px) {
  .menu .nav__link {
    font-size: 40px;
  }
}

@media (max-width: 1280px) {
  .menu .nav__link {
    font-size: 32px;
  }

  .menu .nav__link:hover,
  .menu .nav__link:focus {
    transform: translate(26px, 0);
  }

  .menu .nav__link:active {
    transform: translate(26px, 0) scale(0.94);
  }
}

@media (max-width: 1024px) {
  .menu .nav__link {
    font-size: 28px;
    line-height: 0.928;
  }
}

@media (max-width: 864px) and (min-width: 768px) {
  .menu .nav__link {
    font-size: 24px;
  }
}

@media (max-width: 480px) {

  .menu .nav__link:hover,
  .menu .nav__link:focus {
    transform: translate(16px, 0);
  }

  .menu .nav__link:active {
    transform: translate(16px, 0) scale(0.94);
  }
}

@media (max-width: 390px) {

  .menu .nav__link:hover,
  .menu .nav__link:focus {
    transform: translate(8px, 0);
  }

  .menu .nav__link:active {
    transform: translate(8px, 0) scale(0.94);
  }
}