.courses-card__like {
  display: inline-block;

  margin-left: 4px;
}

@media (max-width: 1280px) {
  .courses-card__like {
    width: 26px;
    height: 30px;
  }
}

@media (max-width: 1024px) {
  .courses-card__like {
    width: 20px;
    height: 24px;
  }
}

@media (max-width: 768px) {
  .courses-card__like {
    width: 24px;
    height: 26px;

    margin-left: 10px;
  }
}