.company__hero-bg {
  width: 100%;
  height: auto;

  background-color: var(--company-hero-bg-color);
}

.company__hero.hero {
  padding: 11.042% 6.928% 4.792%;

  width: 100%;

  color: var(--company-hero-font-color);
  background: url(./company-hero-bg.webp) center left / cover no-repeat;

  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
}

@media (max-width: 1280px) and (min-width: 769px) {
  .company__hero.hero {
    padding-top: 156px;
  }
}

@media (max-width: 768px) {
  .company__hero.hero {
    padding: 178px 4.615% 30px;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}