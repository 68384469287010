.why-mds__contact-card,
.curator__contact-card {
  margin-top: 1.571%;

  width: 100%;
  height: auto;

  color: var(--contact-card-font-color);
  background-color: var(--contact-card-bg-color);
  border-radius: 30px;
}

.curator__contact-card {
  margin-top: 0;
}

.contact-card__form {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 52px 5.1%;

  width: 100%;
  height: 100%;
}

.form__input-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 29.302%;
}

.form__label {
  margin-bottom: 14px;

  font-family: var(--machina-bold);
  font-size: 28px;
  font-weight: 800;
  line-height: 1.035;
}

.form__input {
  padding-bottom: 10px;

  width: 100%;

  font-size: 18px;
  line-height: 1.556;
  color: var(--contact-card-input-color);

  background-color: transparent;
  border-bottom: 1px solid var(--contact-card-input-line-color);

  transition: all 0.2s linear 0s;
}

input:-ms-input-placeholder {
  color: var(--contact-card-input-color);
  opacity: 1;
}

input::-ms-input-placeholder {
  color: var(--contact-card-input-color);
  opacity: 1;
}

input::placeholder {
  color: var(--contact-card-input-color);
  opacity: 1;
}

.form__input:focus {
  color: var(--contact-card-input-focus-color);
  border-color: var(--contact-card-input-focus-color);
}

.form__input.error {
  color: var(--contact-card-input-error-color);
  border-color: var(--contact-card-input-error-color);
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .why-mds__contact-card {
    height: 152px;
  }

  .contact-card__form {
    padding: 38px 3.5%;
  }

  .form__label {
    font-size: 24px;
  }

  .form__input {
    font-size: 17px;
  }
}

@media (max-width: 1024px) {
  .why-mds__contact-card {
    margin-top: 28px;

    width: 100%;
    height: auto;
  }

  .contact-card__form {
    display: grid;
    grid-template: auto / repeat(2, 1fr);

    padding: 40px 5.1%;

    width: 100%;
    height: 100%;
  }

  .form__input-box {

    width: 90%;
  }
}

@media (max-width: 680px) {
  .why-mds__contact-card {
    margin-top: 4.572%;

    width: 100%;
    height: 358px;
  }

  .contact-card__form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;

    padding: 49px 15px 14px;
  }

  .form__input-box {
    margin: 0 auto;

    width: 93.125%;
  }

  .form__input-box:first-child {
    margin-bottom: 18px;
  }

  .form__label {
    margin-bottom: 12px;
    font-size: 28px;
  }

  .form__input {
    padding-bottom: 8px;
    font-size: 18px;
  }
}