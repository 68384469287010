.courses {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  height: auto;

  color: var(--corses-font-color);
  border-color: var(--corses-bg-color);

  user-select: none;
}