.courses .hero__content.content {
  position: relative;
  padding: 2.292% 6.770% 5.105% 7.084%;
  width: 100%;
  z-index: 2;
}

.courses .content__bottom-box {
  padding-bottom: 1.452%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  border-bottom: 1px solid var(--corses-hero-line-color);
  border-bottom-left-radius: 34px;
  border-bottom-right-radius: 34px;
}

@media (max-width: 768px) {
  .courses .hero__content.content {
    padding: 4.103% 4.359% 74px;
  }

  .courses .content__bottom-box {
    padding-bottom: 0;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    border-bottom: none;
  }
}