.home__faq-bg {
  width: 100%;
  height: auto;

  background-color: var(--faq-subbg-color);
}

.home__faq.faq {
  padding: 4.271% 7.917%;

  width: 100%;
  height: auto;

  color: var(--faq-font-color);
  background-color: var(--faq-bg-color);

  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
}

@media (max-width: 768px) {
  .home__faq.faq {
    padding: 64px 5.128% 80px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}