.start__accordion.accordion .accordion-item .accordion-button {
  padding: 0;
  margin: 0 auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 93.583%;
  max-width: 400px;
  height: 56px;

  font-family: var(--machina-bold);
  font-size: 16px;
  font-weight: 800;
  line-height: 6;

  background: transparent;

  border: 1px solid var(--start-btn-border-color);
  border-radius: 50px;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.start__accordion .accordion-item .accordion-button::after {
  content: none;
}

.start__accordion.accordion .accordion-item:first-of-type .accordion-button,
.start__accordion.accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 50px;
}

.start__accordion.accordion .accordion-item:last-of-type .accordion-button {
  margin-top: 30px;
}

.start__accordion .accordion-item .accordion-button.collapsed,
.start__accordion .accordion-item:first-of-type .accordion-button.collapsed,
.start__accordion .accordion-item:first-of-type .accordion-button.collapsed:active,
.start__accordion .accordion-item:last-of-type .accordion-button.collapsed,
.start__accordion .accordion-item:last-of-type .accordion-button.collapsed:active {
  -webkit-box-shadow: inset 0 0 0 0 var(--start-btn-hover-bg-color);
  box-shadow: inset 0 0 0 0 var(--start-btn-hover-bg-color);
  border-color: var(--start-btn-border-color);
  color: inherit;
}

.start__accordion.accordion .accordion-item .accordion-button,
.start__accordion.accordion .accordion-item .accordion-button:hover,
.start__accordion.accordion .accordion-item .accordion-button:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--start-btn-hover-bg-color);
  box-shadow: inset 0 10em 0 0 var(--start-btn-hover-bg-color);
  border-color: var(--start-btn-hover-border-color);
  color: var(--start-btn-hover-font-color);
}