.possibilities__item {
  padding: 26px 32px 26px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  background: transparent;

  border: 1px solid var(--company-possibilities-item-line-color);
  border-radius: 30px;
}

.possibilities .item__img {
  margin-bottom: 18px;
}

@media (max-width: 768px) {
  .possibilities__item {
    padding: 20px 26px 28px;
  }
}