.footer {
  width: 100%;
  height: auto;

  background-color: var(--footer-subbg-color);
}

.footer__bg {
  padding: 118px 6.980% 66px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;

  width: 100%;
  height: auto;

  color: var(--footer-font-color);
  background-color: var(--footer-bg-color);
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
}

.footer__nav {
  display: grid;
  grid-template-columns: 24% repeat(4, 19%);
  grid-template-rows: auto 68px;

  width: 100%;
  height: auto;

  user-select: none;
}

.footer__logo.logo,
.footer__msgr-links.msgr-links,
.footer__nav-links.nav-links,
.footer__network-links.network-links,
.footer__contact-links.contact-links,
.footer__info-links.info-links {
  width: 100%;
  height: 100%;
}

.footer__msgr-links.msgr-links {
  margin-top: 6px;
  margin-left: -1.515%;
}

.footer__nav-links.nav-links,
.footer__network-links.network-links,
.footer__contact-links.contact-links,
.footer__info-links.info-links {
  margin-top: 4px;
}

.footer__nav-links.nav-links,
.footer__network-links.network-links,
.footer__contact-links.contact-links {
  padding-right: 20px;
}

.footer__logo.logo {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.footer__msgr-links.msgr-links {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.footer__nav-links.nav-links {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}

.footer__network-links.network-links {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
}

.footer__contact-links.contact-links {
  grid-column: 4 / 5;
  grid-row: 1 / 3;
}

.footer__info-links.info-links {
  grid-column: 5 / 6;
  grid-row: 1 / 3;
}

.footer__logo.logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  height: min-content;
}

.msgr-links__items {
  margin: 0;
  padding: 0;

  width: 208px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.msgr-links__item,
.footer__link {
  width: 68px;
  height: 68px;
}

.footer__link {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  transition: var(--link-animation);

  background: url(./imgs/msgr-bg.webp) center center / 68px 68px no-repeat;
}

.footer__link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: var(--link-animation);
  opacity: 0;

  background: url(./imgs/msgr-bg-hover.webp) center center / 68px 68px no-repeat;
}

.footer__link:hover::before {
  opacity: 1;
}

.nav-links__title,
.network-links__title,
.contact-links__title,
.info-links__title {
  margin-bottom: 16px;

  font-family: var(--machina-bold);
  font-size: 18px;
  font-weight: 800;
  line-height: 1.057;
  letter-spacing: -0.48px;
}

.nav-links__items,
.network-links__items,
.contact-links__items,
.info-links__items {
  margin: 0;
  padding: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.nav-links__item,
.network-links__item,
.contact-links__item,
.info-links__item {
  margin-bottom: 2px;
}

.nav-links__item:last-child,
.network-links__item:last-child,
.contact-links__item:last-child,
.info-links__item:last-child {
  margin-bottom: 0;
}

.nav-links__link,
.network-links__network-link,
.contact-links-tel,
.contact-links-mail,
.contact-links-location,
.info-links__network-link,
.info-links__link {
  font-family: var(--machina-light);
  font-size: 15px;
  font-weight: 300;
  line-height: 1.269;
  letter-spacing: -0.48px;

  color: var(--footer-font-color);

  opacity: 0.8;

  transition: var(--link-animation);
  word-break: break-all;
}

.nav-links__link:hover,
.nav-links__link:focus,
.network-links__network-link:hover,
.network-links__network-link:focus,
.contact-links-tel:hover,
.contact-links-tel:focus,
.contact-links-mail:hover,
.contact-links-mail:focus,
.contact-links-location:hover,
.contact-links-location:focus,
.info-links__network-link:hover,
.info-links__network-link:focus,
.info-links__link:focus,
.info-links__link:hover {
  color: var(--footer-font-hover-color);
  opacity: 1;
}

.nav-links__link:active,
.network-links__network-link:active,
.contact-links-tel:active,
.contact-links-mail:active,
.contact-links-location:active,
.info-links__network-link:active,
.info-links__link:active {
  color: var(--footer-font-color);
  opacity: 0.8;
}

.footer__paycard-icons {
  margin-right: 3.875%;

  width: 112px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 1400px) and (min-width: 1153px) {

  .nav-links__title,
  .network-links__title,
  .contact-links__title,
  .info-links__title {
    margin-bottom: 14px;

    font-size: 15px;
    line-height: 1.269;
  }

  .nav-links__link,
  .network-links__network-link,
  .contact-links-tel,
  .contact-links-mail,
  .contact-links-location,
  .info-links__network-link,
  .info-links__link {
    font-size: 14px;
    line-height: 1.36;
  }
}

@media (max-width: 1152px) {
  .footer__bg {
    padding: 38px 5.642% 56px;

    align-items: flex-start;
  }

  .footer__nav {
    margin-bottom: 35px;

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 40px 40px auto 38px auto 34px 68px;
  }

  .footer__logo.logo {
    margin-left: 0.5%;

    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  .footer__msgr-links.msgr-links {
    grid-column: 1 / 3;
    grid-row: 7 / 8;
  }

  .footer__nav-links.nav-links {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .footer__network-links.network-links {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .footer__contact-links.contact-links {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
  }

  .footer__info-links.info-links {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
  }

  .nav-links__item,
  .network-links__item,
  .contact-links__item,
  .info-links__item {
    margin-bottom: 3px;
  }

  .footer__paycard-icons {
    margin-left: -1.5%;
  }

  .footer__msgr-links.msgr-links {
    margin-left: -2.2%;
  }

  .nav-links__title,
  .network-links__title,
  .contact-links__title,
  .info-links__title {
    font-size: 20px;
  }

  .nav-links__link,
  .network-links__network-link,
  .contact-links-tel,
  .contact-links-mail,
  .contact-links-location,
  .info-links__network-link,
  .info-links__link {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .footer__nav {
    grid-template-columns: 47.5% 52.5%;
    grid-template-rows: 40px 40px auto 38px auto 34px auto 34px 68px;
  }

  .footer__bg {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .footer__contact-links.contact-links {
    grid-column: 1 / 3;
  }

  .footer__info-links.info-links {
    grid-column: 1 / 3;
    grid-row: 7 / 8;
  }

  .footer__msgr-links.msgr-links {
    grid-row: 9 / 10;
  }

  .nav-links__title,
  .network-links__title,
  .contact-links__title,
  .info-links__title {
    margin-bottom: 14px;

    font-size: 15px;
    line-height: 1.269;
  }

  .nav-links__link,
  .network-links__network-link,
  .contact-links-tel,
  .contact-links-mail,
  .contact-links-location,
  .info-links__network-link,
  .info-links__link {
    font-size: 14px;
    line-height: 1.36;
  }
}

.footer__botbar {
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}

.footer__botbar__company-text {
  margin: 0;
  margin-right: 20px;
  font-family: var(--machina-light);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;

  color: var(--footer-font-color);
  opacity: 0.8;
}

@media (max-width: 1152px) {
  .footer__botbar {
    margin-top: 0;
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: flex-start;
  }

  .footer__botbar__company-text {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .footer__botbar__company-text {
    font-size: 12px;
  }
}