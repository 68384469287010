.level {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  height: auto;

  background-color: var(--corses-font-color);

  user-select: none;
}

.level-body {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  height: auto;
}

.level-body__header-bg {
  width: 100%;
  height: 116px;
  background-color: var(--footer-bg-color);
}

@media (max-width: 768px) {
  .level-body__header-bg {
    height: 128px;
  }
}

@media (max-width: 768px) {
  .level-body__mobile.mobile {
    padding: 64px 12px;
  }

  .mobile__title {
    margin-bottom: 8px;
    font-family: var(--machina-bold);
    font-size: 48px;
    font-weight: 800;
    line-height: 1.208;
    text-align: center;
  }

  .cmobile__level-name {
    margin-bottom: 8px;
    font-family: var(--machina-regulag);
    font-weight: 400;
    font-size: 18px;
    line-height: 1.61;
    text-align: center;
  }

  .mobile__price {
    margin-bottom: 24px;
    padding: 24px 0;

    display: flex;
    flex-flow: column nowrap;

    width: 100%;

    border: 2px solid #FF5C00;
    border-radius: 20px;
  }

  .mobile__price-title {
    margin: 0;
    margin-bottom: 8px;
    font-family: var(--machina-regulag);
    font-weight: 400;
    font-size: 18px;
    line-height: 1.61;
    text-align: center;
  }

  .mobile__price-number {
    margin: 0;
    font-family: var(--machina-bold);
    font-size: 40px;
    font-weight: 800;
    line-height: 1.208;
    text-align: center;
    color: #FF5C00;
    text-align: center;
  }

  .mobile__card {
    padding: 32px 24px;
    margin-bottom: 48px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;

    border-radius: 20px;
    background-color: #4638FF;
    border: 1px solid #4638FF;
    color: var(--white);
  }

  .mobile .card__level-tags {
    margin-bottom: 0;
  }

  .cmobile__level-description {
    margin: 0;
    margin-bottom: 32px;
    font-family: var(--machina-regulag);
    font-weight: 400;
    font-size: 18px;
    line-height: 1.61;
  }

  .mobile-card__level-time {
    padding: 10px 14px;
    margin: 0 auto;
    margin-bottom: 20px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: fit-content;

    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 20px;
  }

  .mobile__level-date {
    margin: 0;
    margin-bottom: 32px;
    font-family: var(--machina-regulag);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.208;
    text-align: center;
  }

  .mobile__btn-link {
    padding: 0 20px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 80px;

    font-family: var(--machina-bold);
    font-size: 16px;
    font-weight: 800;
    line-height: 1.208;
    text-align: center;

    border: 1px solid var(--company-hero-btn-border-color);
    border-radius: 20px;

    color: var(--company-hero-btn-font-color);
    background: transparent;

    -webkit-box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
    box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);

    -webkit-transition: var(--btn-animation);
    transition: var(--btn-animation);
  }

  .mobile__btn-link:hover,
  .mobile__btn-link:focus {
    -webkit-box-shadow: inset 0 0 0 0 transparent;
    box-shadow: inset 0 0 0 0 transparent;
    color: var(--company-hero-btn-font-hover-color);
  }

  .mobile__btn-link:active {
    -webkit-box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
    box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
    color: var(--company-hero-btn-font-color);
  }
}

.level-body__desktop.desktop {
  margin: 0;
  padding: 4.166vw 6.770vw;

  display: grid;
  grid-template: 8.125vw 1.666vw auto 4.166vw 5.625vw / 24.791vw 4.375vw 28.541vw 28.125vw;
  gap: 0;

  width: 100%;
  height: auto;
}

.desktop__card {
  grid-column: 1 / 2;
  grid-row: 1 / 6;

  width: 100%;
  height: 100%;
}

.desktop__topography {
  grid-column: 3 / 4;
  grid-row: 1 / 2;

  width: 100%;
  height: 100%;
}

.desktop__level-description {
  grid-column: 3 / 5;
  grid-row: 3 / 4;

  width: 100%;
  height: 100%;
}

.desktop-card__level-date-wrap {
  grid-column: 3 / 4;
  grid-row: 5 / 6;

  width: 100%;
  height: 100%;
}

.desktop__btn-link {
  grid-column: 4 / 5;
  grid-row: 5 / 6;

  width: 100%;
  height: 100%;
}

.desktop__card {
  padding: 3.75vw 2.5vw;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 1.5625vw;
  background-color: #4638FF;
  color: var(--white);
}

.desktop__card .card__level-title {
  margin-bottom: 0.833vw;
  font-family: var(--machina-bold);
  font-weight: 800;
  font-size: 2.5vw;
  line-height: 1;
  text-align: center;
}

.desktop__card .card__level-name {
  margin-bottom: 1.25vw;
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.208;
}

.desktop__card .card__level-time {
  padding: 0.520vw 0.729vw;
  margin-bottom: 1.666vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 1.041vw;
}

.desktop__card .card__level-time-icon {
  margin-right: 4px;
  width: 0.833vw;
  height: 0.833vw;
}

.desktop__card .card__level-time-text {
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 1;
}

.desktop__card .card__level-time-text.bold {
  margin-left: 4px;
  font-weight: 800;
}

.desktop__card .card__level-tags-title {
  margin: 0;
  margin-top: 2.5vw;
  margin-bottom: 2.083vw;

  width: 100%;

  font-family: var(--machina-bold);
  font-weight: 800;
  font-size: 1.25vw;
  line-height: 1.2;
  text-align: start;
}

.desktop__card .card__level-tags {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template: 1fr / 1fr;
  gap: 0.416vw;

  width: 100%;
}

.desktop__card .card__level-tag {
  padding: 0.520vw 1.666vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: fit-content;

  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 1vw;

  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 1;
  text-align: center;
}

.desktop__topography {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
}

.desktop__title {
  margin-bottom: 1.25vw;
  font-family: var(--machina-bold);
  font-size: 2.5vw;
  font-weight: 800;
  line-height: 1.208;
  text-align: start;
}

.desktop__level-name {
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 1.666vw;
  line-height: 1.2;
  text-align: center;
}

.desktop__price-wrap {
  grid-column: 4 / 5;
  grid-row: 1 / 2;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 100%;
}

.desktop__price {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 19.166vw;
  height: 100%;

  border: 2px solid #FF5C00;
  border-radius: 1.25vw;
}

.desktop__price-title {
  margin: 0;
  margin-bottom: 1.25vw;
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 1.666vw;
  line-height: 1;
  text-align: center;
}

.desktop__price-number {
  margin: 0;
  font-family: var(--machina-bold);
  font-size: 3.333vw;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  color: #FF5C00;
  text-align: center;
}

.desktop__level-description {
  margin: 0;
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 1.0416vw;
  line-height: 1.61;
  max-width: 50vw;
}

.desktop-card__level-date-wrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.desktop-card__level-date-wrap .desktop-card__level-time {
  padding: 0.833vw 1.666vw;
  margin-bottom: 1.458vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: 1px solid #000000;
  border-radius: 1.562vw;
}

.desktop-card__level-date-wrap .card__level-time-icon {
  margin-right: 8px;
  width: 1.145vw;
  height: 1.145vw;
}

.desktop-card__level-date-wrap.card__level-time-text {
  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 1.041vw;
  line-height: 1;
}

.desktop-card__level-date-wrap .card__level-time-text.bold {
  margin-left: 8px;
  font-weight: 800;
}

.desktop__level-date {
  margin: 0;
  font-family: var(--machina-regulag);
  font-weight: 500;
  font-size: 1.041vw;
  line-height: 1;
  text-align: center;
}

.desktop__btn-link {
  padding: 0 1vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  font-family: var(--machina-bold);
  font-size: 1.666vw;
  font-weight: 800;
  line-height: 1;
  text-align: center;

  border: 1px solid var(--company-hero-btn-border-color);
  border-radius: 1.25vw;

  color: var(--company-hero-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.desktop__btn-link:hover,
.desktop__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--company-hero-btn-font-hover-color);
}

.desktop__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
  color: var(--company-hero-btn-font-color);
}