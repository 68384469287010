/* home why mds */
.content__video-container {
  position: relative;

  grid-column: 1 / 2;
  grid-row: 1 / 3;

  margin-right: 6.921%;
}

.content__video-container>div,
.reviews__item .item__video-container>div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  border-radius: 30px;

  z-index: 1;
}

.content__video-iframe,
.reviews__item .item__video-iframe {
  width: 100%;
  height: 100%;

  border-radius: 30px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .content__video-container {
    margin: 0;

    width: 100%;
    height: 434px;
  }
}

@media (max-width: 768px) {
  .content__video-container {
    margin: 0 -3.354%;

    width: 106.708%;
    height: 434px;
  }
}