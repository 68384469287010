@keyframes navLink {
  0% {
    opacity: 0;
    translate: 0 -50px;
    scale: 1 0;
  }

  100% {
    opacity: 1;
    translate: 0 0;
    scale: 1 1;
  }
}

.nav__item,
.contacts__item,
.menu__msgr-item,
.menu__lang-select {
  animation-name: navLink;
  animation-duration: 0.25s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.nav__item:nth-child(2),
.contacts__item:nth-child(1) {
  animation-delay: 0s;
}

.nav__item:nth-child(3),
.menu__msgr-item:nth-child(1) {
  animation-delay: 0.05s;
}

.contacts__item:nth-child(2) {
  animation-delay: 0.1s;
}

.nav__item:nth-child(4),
.menu__msgr-item:nth-child(2) {
  animation-delay: 0.15s;
}

.nav__item:nth-child(5),
.menu__msgr-item:nth-child(3) {
  animation-delay: 0.2s;
}

.nav__item:nth-child(6),
.contacts__item:nth-child(3) {
  animation-delay: 0.25s;
}

@media (max-width: 768px) {

  .nav__item:nth-child(2) {
    animation-delay: 0s;
  }

  .nav__item:nth-child(3) {
    animation-delay: 0.05s;
  }

  .nav__item:nth-child(4) {
    animation-delay: 0.1s;
  }

  .nav__item:nth-child(5) {
    animation-delay: 0.15s;
  }

  .nav__item:nth-child(6) {
    animation-delay: 0.2s;
  }

  .contacts__item:nth-child(1) {
    animation-delay: 0.25s;
  }

  .contacts__item:nth-child(2),
  .menu__msgr-item:nth-child(1),
  .menu__lang-select {
    animation-delay: 0.3s;
  }


  .menu__msgr-item:nth-child(2) {
    animation-delay: 0.325s;
  }

  .contacts__item:nth-child(3),
  .menu__msgr-item:nth-child(3) {
    animation-delay: 0.35s;
  }
}