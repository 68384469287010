.possibilities .accordion-item,
.possibilities .accordion-item:first-of-type,
.possibilities .accordion-item:last-of-type {
  margin-bottom: 4px;

  width: 100%;
  height: auto;

  border: none;
  border-radius: 0;
}