.content__item.item {
  position: relative;

  padding-top: 4.453%;
  padding-left: 16.142%;
  padding-bottom: 5.566%;
  margin-right: 2.783%;

  display: flex;
  flex-flow: column nowrap;

  border-bottom: 1px solid var(--why-mds-item-line-color);
}

.content__item.item:last-child {
  border-bottom: none;
}

.content__item.item:first-child {
  padding-top: 2.969%;
}

@media (max-width: 1280px) {
  .content__item.item {
    padding-left: 20%;
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .content__item.item {
    padding: 148px 0 23px;
  }

  .content__item.item:first-child {
    padding: 160px 0 23px;
  }
}