.company__projects.projects {
  padding: 5.729% 13.906%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;

  color: var(--company-projects-font-color);
  background: url(./projects-bg.webp) center left / cover no-repeat;
}

.anim-box {
  margin-bottom: 32px;
  width: 100%;
  height: auto;
}

.projects__container {
  padding: 24px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: auto;

  border-radius: 30px;
  background-color: var(--company-projects-container-bg-color);
}

@media (max-width: 1280px) and (min-width: 769px) {
  .company__projects.projects {
    padding: 5.729% 6.8%;
  }
}

@media (max-width: 768px) {
  .company__projects.projects {
    padding: 0;

    background: var(--company-projects-container-bg-color);
  }

  .anim-box {
    margin-bottom: 14px;
  }

  .projects__container {
    padding: 24px 0 0;

    border-radius: 0;
  }
}