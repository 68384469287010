.home__team {
  padding: 3.542% 6.875%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;

  color: var(--team-font-color);
  background: var(--team-bg-color);

  user-select: none;
}

.home__bottom-line {
  width: 100%;
  height: 32px;

  border: 1px solid black;
  border-top: none;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

@media (max-width: 768px) {
  .home__team {
    padding: 52px 5.129% 20px;
  }

  .home__bottom-line {
    display: none;
  }
}

@media (max-width: 520px) {
  .home__team {
    padding: 13.847% 5.129% 5.129%;
  }
}