.suitable__item.item {
  display: grid;
  grid-template: auto 1fr / 36px 1fr;

  margin-top: 22px;
  padding-bottom: 24px;

  border-bottom: 1px solid var(--corses-suitable-line-color);
}

.suitable__item .item__number {
  grid-row: 1 / 2;
  grid-column: 1 / 2;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  color: var(--corses-suitable-item-title-color);
}

.suitable__item.item:first-child {
  margin-top: 0;
}

.suitable__item.item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

@media (max-width: 1280px) and (min-width: 769px) {
  .suitable__item .item__number {
    font-size: 20px;
  }
}

@media (max-width: 854px) and (min-width: 769px) {
  .suitable__item .item__number {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .suitable__item.item {
    grid-template: auto 1fr / 24px 1fr;

    margin-top: 18px;
    padding-bottom: 22px;
  }

  .suitable__item .item__number {
    font-size: 18px;
    line-height: 1.611;
  }
}