.format__item.item {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
}

.format__item .item__wrap {
  padding: 0 2.5vw;
  margin-bottom: 1.25vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 186px;

  border: 2px solid #FF5C00;
  border-radius: 30px;
}

.format__item .item__inner-text {
  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.25;
  text-align: center;

  color: var(--corses-suitable-title-color);
}

.format__item .item__star {
  position: absolute;
  left: 86%;
  top: -1.9vw;

  height: 5.208vw;
  width: 5.208vw;
}

.format__item .item__sub-text {
  margin: 0 2.083vw;

  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.555;
  text-align: center;
}

@media (max-width: 1440px) and (min-width: 769px) {
  .format__item .item__inner-text {
    font-size: 30px;
  }

  .format__item .item__sub-text {
    font-size: 17px;
  }
}

@media (max-width: 854px) and (min-width: 769px) {
  .format__item .item__inner-text {
    font-size: 27px;
  }

  .format__item .item__sub-text {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .format__item .item__wrap {
    padding: 0 8px;
    margin-bottom: 16px;

    height: 120px;

    border-radius: 30px;
  }

  .format__item .item__inner-text {
    font-size: 24px;
  }

  .format__item .item__star {
    left: 88%;
    top: -26px;

    height: 52px;
    width: 52px;
  }

  .format__item .item__sub-text {
    margin: 0;
    font-size: 14px;
  }
}