.menu-btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 180px;
  height: 44px;

  border: 1px solid var(--btn-and-link-border-color);
  border-radius: 24px;
  background-color: transparent;

  -webkit-box-shadow: inset 0 0 0 0 var(--menu-btn-hover-bg-color);
  box-shadow: inset 0 0 0 0 var(--menu-btn-hover-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.menu-btn:hover,
.menu-btn:focus {
  -webkit-box-shadow: inset 0 10em 0 0 var(--menu-btn-hover-bg-color);
  box-shadow: inset 0 10em 0 0 var(--menu-btn-hover-bg-color);
}

.menu-btn:active {
  -webkit-box-shadow: inset 0 0 0 0 var(--menu-btn-hover-bg-color);
  box-shadow: inset 0 0 0 0 var(--menu-btn-hover-bg-color);
}

.menu-btn__line-box {
  position: relative;

  margin-right: 8px;

  width: 16px;
  height: 10px;
}

.menu-btn__top-line,
.menu-btn__mid-line,
.menu-btn__bot-line {
  position: absolute;
  left: 0;

  width: 100%;
  height: 1px;

  background-color: var(--btn-and-link-font-color);

  transition: all 0.25s linear;
}

.menu-btn__top-line {
  top: 0;
}

.menu-visible .menu-btn__top-line {
  top: 4.5px;
  transform: rotate(45deg);
}

.menu-btn__mid-line {
  top: 4.5px;
}

.menu-visible .menu-btn__mid-line {
  transform: scale(0);
}

.menu-btn__bot-line {
  bottom: 0;
}

.menu-visible .menu-btn__bot-line {
  bottom: 4.5px;
  transform: rotate(-45deg);
}

.menu-btn__text {
  font-size: 16px;
  line-height: 1;
  color: var(--btn-and-link-font-color);
}