.company .hero__bottom-line {
  margin-top: 5.805%;

  width: 100%;
  height: 32px;

  background: transparent;
  border-bottom: 1px solid var(--company-hero-bot-line-color);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

@media (max-width: 768px) {
  .company .hero__bottom-line {
    margin-top: 0;

    width: 100%;
    height: 30px;

    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}