.courses__find.find {
  padding: 4.479% 6.875% 5.208%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;

  color: var(--corses-find-font-color);
  background: url(./find-bg.webp) center right / cover no-repeat;

  transition: all 0.25s ease-in-out 0s;
}

@media (max-width: 768px) {
  .courses__find.find {
    padding: 82px 5.128% 32px;
  }

  .courses__find.find>div:nth-child(2) {
    margin-bottom: 60px;

    width: 100%;
    height: auto;

    background: transparent;
  }
}

.find__btn-link {
  padding: 28px 80px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  height: 76px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--corses-hero-btn-bg-color);
  border-radius: 24px;

  color: var(--corses-hero-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.find__btn-link:hover,
.find__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-hero-btn-font-hover-color);
}

.find__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  color: var(--corses-hero-btn-font-color);
}

@media (max-width: 1280px) and (min-width: 769px) {
  .find__btn-link {
    height: 60px;
    font-size: 18px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .find__btn-link {
    height: 48px;
    font-size: 14px;
    border-radius: 14px;
  }
}

@media (max-width: 768px) {
  .find__btn-link {
    margin: 0;
    padding: 32px 0;

    width: 100%;
    height: 60px;

    font-size: 16px;
    line-height: 1.75;
    text-align: center;
  }
}

.find__botbar {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.find__botbar__divider {
  margin-top: 3.333vw;
  margin-bottom: 2.916vw;

  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 24px;
  line-height: 1.208;
}

.find__botbar__text {
  margin-bottom: 2.5vw;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  font-family: var(--machina-regulag);
  font-weight: 400;
  font-size: 32px;
  line-height: 1.094;
  text-align: center;
}

.find__botbar__colored-text {
  margin-left: 8px;
  font-family: var(--machina-bold);
  font-weight: 800;
  font-size: 34px;
  color: #FF5C00;
}

@media (max-width: 768px) {
  .find__botbar__divider {
    margin-top: 24px;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .find__botbar__text {
    margin-bottom: 48px;
    flex-flow: column nowrap;
    font-size: 18px;
  }

  .find__botbar__colored-text {
    margin-top: 8px;
    font-size: 24px;
  }
}