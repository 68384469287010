.contact {
  margin: 0;
  padding: 116px 0 112px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  min-height: 100vh;

  background: url(./contact-bg.webp) center / cover no-repeat;

  user-select: none;
}

.contact__form.form {
  margin-top: 50px;
  padding: 52px 68px;

  width: 756px;
  height: auto;

  border-radius: 30px;
  background-color: var(--contact-form-bg-color);

  color: var(--contact-form-font-color);
}

.contact .form__box,
.contact .form__input-box {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: auto;
}

.contact .form__input-box {
  margin-bottom: 20px;
}

.contact .form__input-box:nth-child(4) {
  margin-bottom: 18px;
}

.form__input-checkbox-box {
  position: relative;

  margin-bottom: 36px;

  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  justify-content: flex-end;
}

.contact .form__label {
  margin-bottom: 14px;

  width: 100%;

  font-family: var(--machina-bold);
  font-size: 28px;
  font-weight: 800;
  line-height: 1.035;
}

.contact .form__input {
  padding-bottom: 6px;

  width: 100%;

  font-size: 18px;
  line-height: 1.556;
  color: var(--contact-form-font-color);

  background-color: transparent;
  border-bottom: 1px solid var(--contact-form-border-color);

  transition: all 0.2s linear 0s;
}

.contact textarea.form__input {
  padding-bottom: 0;

  height: 36px;
}

.contact input:-ms-input-placeholder {
  color: var(--contact-form-placeholder-color);
  opacity: 1;
}

.contact input::-ms-input-placeholder {
  color: var(--contact-form-placeholder-color);
  opacity: 1;
}

.contact input::placeholder {
  color: var(--contact-form-placeholder-color);
  opacity: 1;
}

.contact textarea::-webkit-input-placeholder {
  color: var(--contact-form-placeholder-color);
}

.contact textarea:-moz-placeholder {
  color: var(--contact-form-placeholder-color);
}

.contact textarea::-moz-placeholder {
  color: var(--contact-form-placeholder-color);
}

.contact textarea:-ms-input-placeholder {
  color: var(--contact-form-placeholder-color);
}

.contact textarea::placeholder {
  color: var(--contact-form-placeholder-color);
}

.contact .form__input:focus {
  color: var(--contact-form-font-color);
  border-bottom: 1px solid var(--contact-form-border-color);
}

.contact .form__input.error {
  color: var(--contact-form-error-color);
  border-color: var(--contact-form-error-color);
}

.form__label-checkbox {
  font-size: 15px;
  line-height: 1.266;
}

.form__label-checkbox {
  margin-left: 36px;

  transition: all 0.2s linear 0s;
}

.form__input-checkbox {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;

  width: 24px;
  height: 24px;

  z-index: 1;
  cursor: pointer;

  transition: all 0.2s linear 0s;
}

.form__label-checkbox:before {
  content: "";
  display: block;

  margin-top: -3px;

  width: 24px;
  height: 24px;

  border: 1px solid var(--contact-form-checkbox-border-color);
  background: transparent;
  border-radius: 6px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  transition: all 0.2s linear 0s;
}

.form__label-checkbox:after {
  content: "";

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-left: 3px;

  width: 18px;
  height: 18px;

  background: transparent;
  border-radius: 6px;

  z-index: 0;

  transition: all 0.2s linear 0s;
}

.form__input-checkbox.checked+.form__label-checkbox:after {
  background: var(--contact-form-checkbox-checked-color);
}

.form__input-checkbox.error+.form__label-checkbox::before {
  border-color: var(--contact-form-error-color);
}

@media (max-width: 1024px) and (min-width: 769px) {
  .contact__form.form {
    padding: 32px;

    width: 680px;
  }
}

@media (max-width: 768px) {
  .contact {
    padding: 140px 4.615% 68px;
  }

  .contact__form.form {
    margin-top: 14px;
    padding: 44px 28px 20px;

    max-width: 600px;

    border-radius: 20px;
  }

  .form__label-checkbox {
    font-size: 14px;
    line-height: 1.285;
  }

  .contact .form__label {
    margin-bottom: 14px;

    font-size: 24px;
    line-height: 1.208;
  }

  .contact .form__input {
    font-size: 16px;
    line-height: 1.75;
  }

  .contact .form__input-box:nth-child(4) {
    margin-bottom: 26px;
  }

  .contact .form__input-checkbox-box {
    margin-bottom: 26px;
  }
}

@media (max-width: 600px) {
  .contact__form.form {
    padding: 44px 14px 20px;
  }
}