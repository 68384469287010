/* home hero */
.company-card__title {
  max-width: 100%;

  margin: 3.945% 27px;

  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.093;

  color: var(--hero-company-title-font-color);
}

@media (max-width: 1280px) {
  .company-card__title {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .company-card__title {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .company-card__title {
    margin: 12px 26px 14px;

    font-size: 24px;
    line-height: 1.333;
  }
}

/* home start */
.start__title {
  margin-bottom: 12px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;

  text-align: center;
}

.card__title,
.accordion-card__title {
  margin: 0 38px 26px;

  max-width: 332px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
}

@media (max-width: 1280px) {
  .start__title {
    font-size: 38px;
  }
}

@media (max-width: 1024px) {
  .card__title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .start__title {
    margin: 0 0 11px;

    width: 93.583%;

    font-size: 28px;
    line-height: 1.428;
  }

  .accordion-card__title {
    margin: 0 22px 21px;

    font-size: 24px;
  }
}

/* home why mds */
.content__title {
  grid-column: 2 / 3;
  grid-row: 1 / 2;

  margin: 6.401% 0 0 14.101%;
  padding: 0;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
}

.item__title {
  margin: 2.060% 0 2.975%;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
}

@media (max-width: 1280px) {
  .content__title {
    font-size: 44px;
  }
}

@media (max-width: 1024px) {
  .content__title {
    margin: 0;
    margin-left: 4px;

    font-size: 48px;
    line-height: 0.875;
  }
}

@media (max-width: 768px) {
  .item__title {
    margin: 0 0 9px;

    font-size: 18px;
    line-height: 1.611;
  }
}

/* home investors */
.investors__title {
  margin: 0;
  margin-bottom: 6.232%;
  margin-top: -1.187%;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
}

@media (max-width: 1280px) {
  .investors__title {
    font-size: 44px;
  }
}

@media (max-width: 1024px) {
  .investors__title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .investors__title {
    margin-bottom: 40px;

    font-size: 32px;
    line-height: 0.875;
    text-align: center;
  }
}

/* home team */
.team__title {
  margin-bottom: 1.355%;

  max-width: 1092px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
  text-align: center;
}

.badge__title {
  margin-bottom: 1.355%;

  font-family: var(--machina-bold);
  font-size: 28px;
  font-weight: 800;
  line-height: 1.035;
}

@media (max-width: 1280px) {
  .badge__title {
    font-size: 24px;
  }

  .team__title {
    margin-bottom: 28px;
  }
}

@media (max-width: 992px) {
  .badge__title {
    margin-bottom: 0;
    font-size: 17px;
  }

  .team__title {
    font-size: 40px;
  }
}

@media (max-width: 860px) {
  .badge__title {
    font-size: 15px;
  }
}

@media (max-width: 768px) {

  .team__title,
  .badge__title {
    font-size: 28px;
    line-height: 1.035;
  }
}

@media (max-width: 520px) {
  .team__title {
    margin-bottom: 10%;
  }
}

/* home faq */
.faq__title {
  margin-bottom: 3.466%;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.083;
  text-align: center;
}

@media (max-width: 768px) {
  .faq__title {
    margin-bottom: 36px;

    font-size: 28px;
    line-height: 0.928;
  }
}

/* contact form */
.contact__form .form__title {
  margin-bottom: 24px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
}

@media (max-width: 768px) {
  .contact__form .form__title {
    margin-bottom: 24px;

    font-size: 28px;
    line-height: 1.071;
  }
}

/* courses suitable */
.suitable .suitable__title {
  margin-right: 12.092%;
  margin-bottom: 0;

  width: 28.900%;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;

  color: var(--corses-suitable-title-color);
}

.suitable__item .item__title {
  grid-row: 1 / 2;
  grid-column: 2 / 3;

  margin: 0;
  margin-bottom: 10px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  color: var(--corses-suitable-item-title-color);
}

@media (max-width: 1440px) and (min-width: 769px) {
  .suitable .suitable__title {
    font-size: 40px;
  }

  .suitable__item .item__title {
    font-size: 20px;
  }
}

@media (max-width: 854px) and (min-width: 769px) {
  .suitable .suitable__title {
    font-size: 36px;
  }

  .suitable__item .item__title {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .suitable .suitable__title {
    margin-bottom: 34px;

    width: 100%;

    font-size: 28px;
    line-height: 1.071;
    text-align: center;
  }

  .suitable__item .item__title {
    font-size: 18px;
    line-height: 1.611;
  }
}

/* courses find */
.find .find__title {
  margin-bottom: 52px;

  max-width: 962px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
  text-align: center;
}

.find h3.accordion-header__title {
  padding-bottom: 30px;
  margin-bottom: 32px;

  width: 100%;

  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3125;

  border-bottom: 1px solid var(--corses-find-card-line-color);
}

.find h4.accordion-header__title {
  margin-bottom: 41px;

  width: 100%;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.2083;
}

@media (max-width: 992px) and (min-width: 769px) {
  .find .find__title {
    font-size: 42px;
  }

  .find h3.accordion-header__title {
    font-size: 28px;
  }

  .find h4.accordion-header__title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .find .find__title {
    margin-bottom: 28px;

    max-width: 100%;

    font-size: 28px;
    line-height: 1.071;
    text-align: left;
  }

  .find h3.accordion-header__title {
    padding-left: 14px;
  }
}

@media (max-width: 480px) {
  .find h3.accordion-header__title {
    padding-left: 0;
    font-size: 28px;
  }

  .find h4.accordion-header__title {
    font-size: 18px;
  }
}

/* courses stages */
.stages .stages__title {
  margin-bottom: 44px;

  max-width: 780px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
  text-align: center;
}

@media (max-width: 640px) {
  .stages .stages__title {
    margin-bottom: 14px;

    max-width: 100%;

    font-size: 28px;
    line-height: 1.428;
    text-align: center;
  }
}

/* courses curator */
.curator .body__title {
  margin: 0;
  margin-top: -20px;

  flex: 0 0 30.6%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  font-family: var(--machina-bold);
  font-size: 70px;
  font-weight: 800;
  line-height: 1;
}

.curator h3.about__title {
  margin-top: -17px;
  margin-bottom: 11px;

  max-width: 320px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
}

.curator h4.about__title {
  margin: 0;
  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.094;
  color: var(--corses-curator-specialty-font-color);
}

@media (max-width: 1440px) and (min-width: 769px) {
  .curator .body__title {
    margin: 0;
    margin-top: 60px;
    margin-left: 0;
    margin-right: auto;

    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .curator .body__title {
    font-size: 60px;
  }

  .curator h3.about__title {
    font-size: 40px;
  }

  .curator h4.about__title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .curator .body__title {
    margin: 0 auto 18px;

    justify-content: flex-start;

    width: auto;

    font-size: 48px;
    line-height: 1.125;
  }
}

@media (max-width: 520px) {
  .curator .body__title {
    margin: 0 0 18px;
    width: 100%;
  }
}

/* courses students */
.reviews__title,
.works__title {
  margin: 0;

  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3125;
}

.works__item .item__title,
.reviews__item .item__title {
  margin: 0;
  margin-top: 18px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
}

@media (max-width: 1280px) and (min-width: 769px) {

  .reviews__title,
  .works__title {
    font-size: 24px;
  }

  .works__item .item__title,
  .reviews__item .item__title {
    font-size: 18px;
  }
}

@media (max-width: 768px) {

  .reviews__title,
  .works__title {
    margin: 0;
    font-size: 24px;
    line-height: 1.333;
  }

  .reviews__item .item__title {
    margin-top: 10px;

    font-size: 18px;
    line-height: 1.333;
  }

  .works__item .item__title {
    margin-left: -2.5%;
    margin-top: 10px;

    font-size: 16px;
    line-height: 1.75;
    color: var(--corses-students-works-font-mobole-color);
  }
}

/* company possibilities */
.possibilities__title {
  margin-bottom: 22px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
}

.possibilities .item__title {
  margin-bottom: 24px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.203;
}

@media (max-width: 768px) {
  .possibilities__title {
    margin-bottom: 2.052%;

    font-size: 28px;
    line-height: 1.071;
    text-align: center;
  }

  .possibilities .item__title {
    margin-bottom: 10px;
  }
}

/* company why-mds */
.company__why-mds .body__title {
  margin-bottom: 32px;

  font-family: var(--machina-bold);
  font-size: 48px;
  font-weight: 800;
  line-height: 1.125;
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .company__why-mds .body__title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .company__why-mds .body__title {
    margin-bottom: 28px;

    font-size: 32px;
    line-height: 1.3125;
  }
}

/* company start */
.company__start .start__title {
  margin-bottom: 4.186%;

  font-family: var(--machina-bold);
  font-size: 70px;
  font-weight: 800;
  line-height: 1;
  text-align: left;
}

@media (max-width: 1600px) and (min-width: 769px) {
  .company__start .start__title {
    font-size: 52px;
  }
}

@media (max-width: 1280px) and (min-width: 769px) {
  .company__start .start__title {
    font-size: 44px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .company__start .start__title {
    font-size: 34px;
  }
}

@media (max-width: 768px) {
  .company__start .start__title {
    margin-bottom: 18px;

    width: 100%;

    font-size: 36px;
    line-height: 1.071;
    text-align: center;
  }
}

@media (max-width: 520px) {
  .company__start .start__title {
    font-size: 28px;
  }
}

/* company projects */
.projects__head .head__title {
  font-family: var(--machina-bold);
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3125;
}

.projects__item .item__title {
  margin: 0;
  margin-left: 4px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.207;
}

@media (max-width: 1280px) and (min-width: 769px) {
  .projects__head .head__title {
    font-size: 28px;
  }

  .projects__item .item__title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .projects__head .head__title {
    margin-bottom: 40px;

    font-size: 28px;
    line-height: 1.071;
  }
}