.team__body {
  position: relative;

  margin-bottom: 1.355%;

  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .team__body {
    margin-bottom: 0;
  }
}