.why-mds {
  width: 100%;
  color: var(--why-mds-font-color);
  background: url(./why-mds-bg.webp) center right / cover no-repeat;
  overflow: hidden;
}

.why-mds__container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  padding: 0px 6.875% 88px;

  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .why-mds__container {
    padding: 0px 20px 48px;
  }
}