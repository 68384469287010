.form__post-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 5;
}

.post-popup__inner-info {
  padding: 0 24px;

  min-width: 280px;
  height: 220px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  color: var(--contact-card-popup-font-color);
  background-color: var(--contact-card-popup-bg-color);
  border-radius: 30px;
}

.inner-info>div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.post-popup__inner-info .spinner-border {
  width: 80px;
  height: 80px;

  border-width: 8px;

  color: var(--contact-card-popup-spinner-color);
}

@keyframes rotate {
  25% {
    transform: rotate(15deg);
  }

  75% {
    transform: rotate(-15deg);
  }
}

.inner-info__img {
  margin-bottom: 24px;

  width: 80px;
  height: 80px;

  animation-name: rotate;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-play-state: running;
  animation-delay: 0.25s;
  animation-fill-mode: none;
}

.inner-info__title {
  margin: 0;

  font-family: var(--machina-bold);
  font-size: 40px;
  font-weight: 800;
  line-height: 1.125;
  text-transform: uppercase;
}