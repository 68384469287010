.possibilities .accordion-button,
.possibilities .accordion-button.collapsed,
.possibilities .accordion-item:first-of-type,
.possibilities .accordion-item:last-of-type .accordion-button.collapsed {
  padding: 0;
  border: 0;
}

.possibilities .accordion-button::after {
  content: none;
}

.possibilities .accordion-button.collapsed,
.possibilities .accordion-button,
.possibilities .accordion-item:last-of-type .accordion-button.collapsed,
.possibilities .accordion-item:last-of-type .accordion-button,
.possibilities .accordion-item:first-of-type .accordion-button,
.possibilities .accordion-item:first-of-type .accordion-button.collapsed {
  margin: 0 auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 400px;
  height: 56px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1;
  color: var(--company-possibilities-font-color);

  background: transparent;

  border: 1px solid var(--company-possibilities-btn-border-color);
  border-radius: 50px;

  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.possibilities .accordion-button.collapsed,
.possibilities .accordion-item:last-of-type .accordion-button.collapsed,
.possibilities .accordion-item:first-of-type .accordion-button.collapsed {
  margin-bottom: 8px;
}

.possibilities .accordion-button.collapsed:hover,
.possibilities .accordion-button.collapsed:focus,
.possibilities .accordion-item:last-of-type .accordion-button.collapsed:hover,
.possibilities .accordion-item:last-of-type .accordion-button.collapsed:focus,
.possibilities .accordion-item:first-of-type .accordion-button.collapsed:hover,
.possibilities .accordion-item:first-of-type .accordion-button.collapsed:focus,
.possibilities .accordion-button:not(.collapsed),
.possibilities .accordion-item:last-of-type .accordion-button,
.possibilities .accordion-item:first-of-type .accordion-button {
  -webkit-box-shadow: inset 0 10em 0 0 var(--company-possibilities-accent-color);
  box-shadow: inset 0 10em 0 0 var(--company-possibilities-accent-color);
  color: var(--company-possibilities-btn-font-hover-color);
  border-color: var(--company-possibilities-accent-color);
}

.possibilities .accordion-button.collapsed:active,
.possibilities .accordion-button:not(.collapsed):active,
.possibilities .accordion-item:last-of-type .accordion-button.collapsed:active,
.possibilities .accordion-item:last-of-type .accordion-button:active,
.possibilities .accordion-item:first-of-type .accordion-button:active,
.possibilities .accordion-item:first-of-type .accordion-button.collapsed:active {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--company-possibilities-font-color);
  border-color: var(--company-possibilities-btn-border-color);
}