.company__why-mds.why-mds {
  padding: 5.833% 6.979% 4.895%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;

  color: var(--company-why-mds-font-color);
  background: url(./company-why-mds-bg.webp) center right / cover no-repeat;
}

@media (max-width: 768px) {
  .company__why-mds.why-mds {
    padding: 20px 5.128% 40px;
  }
}