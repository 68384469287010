/* home hero */
.courses-card__btn-link {
  position: absolute;
  bottom: 14px;
  left: 14px;
  right: 14px;

  padding: 0 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 80px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  color: var(--hero-courses-btn-font-color);
  text-align: center;

  border: 1px solid var(--hero-courses-btn-bg-color);
  border-radius: 24px;

  -webkit-box-shadow: inset 0 8em 0 0 var(--hero-courses-btn-bg-color);
  box-shadow: inset 0 8em 0 0 var(--hero-courses-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.courses-card__btn-link:hover,
.courses-card__btn-link:focus,
.company-card__btn-link:hover,
.company-card__btn-link:focus {
  color: var(--hero-courses-btn-hover-font-color);

  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
}

.courses-card__btn-link:active {
  color: var(--hero-courses-btn-font-color);

  -webkit-box-shadow: inset 0 8em 0 0 var(--hero-courses-btn-bg-color);
  box-shadow: inset 0 8em 0 0 var(--hero-courses-btn-bg-color);
}

.company-card__btn-link {
  margin: 17px 17px 12px;
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 80px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border-radius: 24px;

  color: var(--hero-company-btn-font-color);

  border: 1px solid var(--hero-company-btn-bg-color);

  -webkit-box-shadow: inset 0 8em 0 0 var(--hero-company-btn-bg-color);
  box-shadow: inset 0 8em 0 0 var(--hero-company-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.company-card__btn-link:hover,
.company-card__btn-link:focus {
  color: var(--hero-company-btn-hover-font-color);
}

.company-card__btn-link:active {
  color: var(--hero-company-btn-font-color);

  -webkit-box-shadow: inset 0 8em 0 0 var(--hero-company-btn-bg-color);
  box-shadow: inset 0 8em 0 0 var(--hero-company-btn-bg-color);
}

@media (max-width: 1280px) {

  .courses-card__btn-link,
  .company-card__btn-link {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {

  .courses-card__btn-link,
  .company-card__btn-link {
    padding: 0 8px;

    height: 60px;

    font-size: 16px;
  }
}

@media (max-width: 768px) {

  .courses-card__btn-link,
  .company-card__btn-link {
    height: 60px;
    font-size: 16px;
    border-radius: 16px;
  }

  .courses-card__btn-link,
  .company-card__btn-link {
    bottom: 10px;
    left: 10px;
    right: 10px;

    line-height: 1.75;
  }

  .company-card__btn-link {
    margin: 10px 10px 12px;

    line-height: 1.25;
  }
}

/* home investors */
.investors__btn-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 4;

  color: var(--investors-btn-font-color);
  background: transparent;

  border: 1px solid var(--investors-btn-borer-color);
  border-radius: 24px;

  -webkit-box-shadow: inset 0 10em 0 0 var(--investors-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--investors-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.investors__btn-link:hover,
.investors__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--investors-btn-hover-font-color);
}

.investors__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--investors-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--investors-btn-bg-color);
  color: var(--investors-btn-font-color);
}

@media (max-width: 1280px) {
  .investors__btn-link {
    height: 64px;
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .investors__btn-link {
    height: 60px;
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .investors__btn-link {
    width: 100%;

    font-size: 16px;
    line-height: 6;
  }
}

/* home sign up */
.sign-up__btn-link {
  position: relative;

  padding: 0 24px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: auto;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;

  color: var(--sign-up-btn-font-color);
  background-color: transparent;

  border: 1px solid var(--sign-up-btn-border-color);
  border-radius: 24px;

  -webkit-box-shadow: inset 0 10em 0 0 var(--sign-up-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--sign-up-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);

  z-index: 3;
}

.sign-up__btn-link:hover,
.sign-up__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--sign-up-btn-hover-font-color);
}

.sign-up__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--sign-up-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--sign-up-btn-bg-color);
  color: var(--sign-up-btn-font-color);
}

@media (max-width: 768px) {
  .sign-up__btn-link {
    padding: 30px 20px;

    width: auto;
    height: auto;

    font-size: 16px;
    line-height: 1.75;
    text-align: center;
  }
}

/* courses hero */
.courses .content__link {
  margin-top: -10px;
  margin-right: 20px;

  padding: 0 12px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 27.933%;
  height: 80px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--corses-hero-btn-bg-color);
  border-radius: 24px;

  color: var(--corses-hero-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.courses .content__link:hover,
.courses .content__link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-hero-btn-font-hover-color);
}

.courses .content__link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-hero-btn-bg-color);
  color: var(--corses-hero-btn-font-color);
}

@media (max-width: 1280px) and (min-width: 769px) {
  .courses .content__link {
    height: 64px;
    font-size: 18px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .courses .content__link {
    height: 48px;
    font-size: 14px;
    border-radius: 14px;
  }
}

@media (max-width: 768px) {
  .courses .content__link {
    margin: 0;

    width: 100%;
    height: 60px;

    font-size: 16px;
    line-height: 1.75;
  }
}

/* courses students */
.reviews__btn-link {
  margin-top: 42px;
  padding: 0 20px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--corses-students-reviews-btn-botder-color);
  border-radius: 24px;

  color: var(--corses-students-reviews-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-students-reviews-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-students-reviews-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.reviews__btn-link:hover,
.reviews__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--corses-students-reviews-btn-font-hover-color);
}

.reviews__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--corses-students-reviews-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--corses-students-reviews-btn-bg-color);
  color: var(--corses-students-reviews-btn-font-color);
}

@media (max-width: 768px) {
  .reviews__btn-link {
    margin-top: 26px;

    height: 56px;

    font-size: 16px;
    line-height: 1.75;

    border-radius: 16px;
  }
}

/* company hero */
.company .content__btn-link {
  padding: 0 20px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--company-hero-btn-border-color);
  border-radius: 24px;

  color: var(--company-hero-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.company .content__btn-link:hover,
.company .content__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--company-hero-btn-font-hover-color);
}

.company .content__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--company-hero-btn-bg-color);
  color: var(--company-hero-btn-font-color);
}

@media (max-width: 1024px) and (min-width: 769px) {
  .company .content__btn-link {
    height: 52px;

    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .company .content__btn-link {
    height: 60px;

    font-size: 16px;
    line-height: 1.75;
  }
}

/* company why-mds */
.company__why-mds .body__btn-link {
  padding: 0 20px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--company-why-mds-accent-color);
  border-radius: 24px;

  color: var(--company-why-mds-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);
  box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.company__why-mds .body__btn-link:hover,
.company__why-mds .body__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  border-color: var(--company-why-mds-font-color);
}

.company__why-mds .body__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);
  box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);
  border-color: var(--company-why-mds-accent-color);
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .company__why-mds .body__btn-link {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .company__why-mds .body__btn-link {
    height: 60px;

    font-size: 16px;
    line-height: 1.75;
  }
}

/* company start */
.company__start .start__btn-link {
  padding: 0 12px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 78.372%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--company-why-mds-accent-color);
  border-radius: 24px;

  color: var(--company-why-mds-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);
  box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.company__start .start__btn-link:hover,
.company__start .start__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  border-color: var(--company-start-btn-border-hover-color);
  color: var(--company-start-btn-font-hover-color);
}

.company__start .start__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);
  box-shadow: inset 0 10em 0 0 var(--company-why-mds-accent-color);
  border-color: var(--company-why-mds-accent-color);
  color: var(--company-why-mds-font-color);
}

@media (max-width: 1024px) and (min-width: 769px) {
  .company__start .start__btn-link {
    height: 60px;

    font-size: 18px;
  }
}


@media (max-width: 768px) {
  .company__start .start__btn-link {
    margin: 0 8px;
    padding: 0 8px;

    width: auto;
    height: 60px;

    font-size: 20px;
    line-height: 1.75;
  }
}

@media (max-width: 520px) {
  .company__start .start__btn-link {
    font-size: 16px;
  }
}

/* company projects */
.projects .btns__btn-link {
  padding: 0 24px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 61.5%;
  height: 75px;

  font-family: var(--machina-bold);
  font-size: 24px;
  font-weight: 800;
  line-height: 1.208;
  text-align: center;

  border: 1px solid var(--company-projects-btn-bg-color);
  border-radius: 24px;

  color: var(--company-projects-btn-font-color);
  background: transparent;

  -webkit-box-shadow: inset 0 10em 0 0 var(--company-projects-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--company-projects-btn-bg-color);

  -webkit-transition: var(--btn-animation);
  transition: var(--btn-animation);
}

.projects .btns__btn-link:hover,
.projects .btns__btn-link:focus {
  -webkit-box-shadow: inset 0 0 0 0 transparent;
  box-shadow: inset 0 0 0 0 transparent;
  color: var(--company-projects-btn-bg-color);
}

.projects .btns__btn-link:active {
  -webkit-box-shadow: inset 0 10em 0 0 var(--company-projects-btn-bg-color);
  box-shadow: inset 0 10em 0 0 var(--company-projects-btn-bg-color);
  color: var(--company-projects-btn-font-color);
}

@media (max-width: 1600px) and (min-width: 769px) {
  .projects .btns__btn-link {
    font-size: 20px;
  }
}

@media (max-width: 1280px) and (min-width: 769px) {
  .projects .btns__btn-link {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .projects .btns__btn-link {
    margin: 0 auto;
    padding: 30px 24px;
    margin-bottom: 24px;

    width: 80.769%;
    height: auto;

    font-size: 16px;
    line-height: 1.75;
  }
}