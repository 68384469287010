/* home hero */
.company-card__img {
  margin: 0 18px;

  flex: 1 0 auto;

  height: auto;

  border-radius: 24px;
  background: url(./hero-company-card-img.webp) center / cover no-repeat;
}

@media (max-width: 768px) {
  .company-card__img {
    margin: 0 10px;

    height: 348px;

    border-radius: 27px;
  }
}

/* home investors */
.investors__img {
  width: 100%;
  height: 100%;

  border-radius: 30px;
  background: url(./home-investors-img.webp) center / cover no-repeat;
}

/* courses curator */
.curator .body__img {
  margin: 0 6.160%;

  flex: 0 0 424px;
  height: 554px;

  border-radius: 30px;
  background: url(./curator-img.webp) center / cover no-repeat;
}

@media (max-width: 1440px) and (min-width: 769px) {
  .curator .body__img {
    margin: 0;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .curator .body__img {
    width: 100%;
    height: 480px;
  }
}

@media (max-width: 768px) {
  .curator .body__img {
    margin: 0 auto 40px;

    width: 98%;
    max-width: 482px;
    min-height: 482px;
  }
}

/* company why-mds */
.company__why-mds .body__img {
  flex: 0 0 52.785%;

  border-radius: 30px;

  background: url(./home-investors-img.webp) center / cover no-repeat;
}

@media (max-width: 1024px) {
  .company__why-mds .body__img {
    flex: 0 0 100%;

    padding-top: 62.285%;
  }
}

/* company start */
.company__start .start__img {
  position: absolute;
  top: 0;
  right: 0;

  margin-top: 3.4%;
  margin-right: 11.3%;
  padding-top: 33.125%;

  width: 34.895%;

  background: url(../pages//company//start//start-laptop.webp) center / 90% no-repeat;
}

@media (max-width: 768px) {
  .company__start .start__img {
    left: 0;

    margin-top: 2%;
    margin-right: 0;
    padding-top: 96%;

    width: 100%;
  }
}