.contacts__item-location {
  font-family: var(--machina-light);
  color: var(--menu-font-color);

  transition: var(--link-animation);
}

.contacts__item-location:hover,
.contacts__item-location:focus {
  color: var(--menu-contact-link-hover-color);
}

.contacts__item-location:active {
  color: var(--menu-font-color);
}

@media (max-width: 1024px) {
  .contacts__item-location {
    font-size: 15px;
    line-height: 1.266;
  }
}