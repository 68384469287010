.start__card {
  width: 100%;
  height: 100%;

  border: 1px solid var(--start-card-border-color);
  border-radius: 30px;

  overflow: hidden;

  transition: all 0.5s linear 0s;
}

.card__img {
  margin: 30px 38px;

  height: 120px;
  width: auto;
}

.card__text.spec {
  margin-bottom: 12px;
}

.card__text.li {
  list-style: inside;
  margin-bottom: 6px;
}

.card__text.li:last-child {
  margin-bottom: 48px;
}

.start__card ul {
  padding-left: 0;
}