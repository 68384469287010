.team__body .card__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: 0;
  width: 100%;
  height: 100%;

  border-radius: 28px;
}