.company .body__rocket-box {
  position: relative;

  flex: 0 0 48.503%;
  padding-top: 38.623%;
}

.company .body__rocket-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 64px;

  background: url(./rocket-bg.webp) bottom center / cover no-repeat;
  border-radius: 24px;
}

.company .body__rocket {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  background: url(./rocket.webp) bottom center / cover no-repeat;
  border-radius: 24px;
}

@media (max-width: 768px) {
  .company .body__rocket-box {
    position: relative;

    margin-top: -44px;

    flex: 0 0 100%;
    padding-top: 90.5%;
  }
}