.works__item .item__lazy-container {
  position: relative;

  flex: 1 1 auto;
  height: 412px;
  border-radius: 24px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.bagels__lazy-placeholder,
.works__item .item__lazy-placeholder,
.why-mds .placeholdeer__lazy-placeholder,
.reviews .placeholdeer__lazy-placeholder,
.projects__item .item__lazy-placeholder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.75);

  border-radius: 24px;
  z-index: 20;

  opacity: 1;
  transition: all 0.25s ease-in-out 0s;
}

.bagels__lazy-placeholder.loaded,
.works__item .item__lazy-placeholder.loaded,
.why-mds .placeholdeer__lazy-placeholder.loaded,
.reviews .placeholdeer__lazy-placeholder.loaded,
.projects__item .item__lazy-placeholder.loaded {
  opacity: 0;
  z-index: 5;
}

.bagels__lazy-placeholder .spinner-border.text-primary,
.works__item .spinner-border.text-primary,
.why-mds .spinner-border.text-primary,
.reviews .spinner-border.text-primary,
.projects__item .spinner-border.text-primary {
  width: 64px;
  height: 64px;

  color: var(--deep-blue) !important;
}

.bagels__lazy-img,
.works__item .item__lazy-img,
.reviews .placeholdeer__lazy-img,
.projects__item .item__lazy-img {
  position: absolute;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: auto;
  height: 100%;

  border-radius: 24px;
  z-index: 5;

  opacity: 0;
  transition: all 0.25s ease-in-out 0s;
}

.bagels__lazy-img.loaded,
.works__item .item__lazy-img.loaded,
.reviews .placeholdeer__lazy-img.loaded,
.projects__item .item__lazy-img {
  opacity: 1;
}

@media (max-width: 768px) {
  .works__item .item__lazy-container {
    height: 600px;
    border-radius: 18px;
  }
}

/* courses hero */
.courses .bagels__lazy-container {
  position: relative;

  margin-top: 220px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 27.292%;
  height: 26vw;

  overflow: hidden;
}

.bagels__lazy-placeholder {
  background-color: transparent;
  border-radius: 0;
}

.bagels__lazy-img {
  top: 0;
  border-radius: 0;
}

@media (max-width: 1280px) and (min-width: 769px) {
  .courses .bagels__lazy-container {
    margin-top: 180px;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .courses .bagels__lazy-container {
    margin-top: 160px;
  }
}

@media (max-width: 768px) {
  .courses .bagels__lazy-container {
    margin: 0;

    width: 42%;
    height: 40vw;
  }
}

@media (max-width: 700px) {
  .courses .bagels__lazy-container {
    width: 48%;
    height: 46vw;
  }
}

@media (max-width: 600px) {
  .courses .bagels__lazy-container {
    width: 52%;
    height: 50vw;
  }
}

@media (max-width: 520px) {
  .courses .bagels__lazy-container {
    width: 66%;
    height: 63vw;
  }
}

@media (max-width: 420px) {
  .courses .bagels__lazy-container {
    margin-top: 20px;

    width: 79%;
    height: 76vw;
  }
}

/* home why-mds */
.why-mds .placeholdeer__lazy-container {
  position: absolute;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 30px;

  overflow: hidden;
}

.why-mds .placeholdeer__lazy-placeholder {
  background-color: transparent;
  border-radius: 30px;
}

.reviews .placeholdeer__lazy-container {
  position: absolute;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 30px;

  overflow: hidden;
}

.reviews .placeholdeer__lazy-placeholder {
  background: transparent;
}

.reviews .spinner-border.text-primary {
  width: 108px;
  height: 108px;

  color: white !important;
}

@media (max-width: 768px) {
  .reviews .spinner-border.text-primary {
    width: 76px;
    height: 76px;

    color: white !important;
  }
}

.projects__item .item__lazy-container {
  position: relative;

  margin-bottom: 10px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  border-radius: 24px;

  overflow: hidden;
}

@media (max-width: 768px) {
  .projects__item .item__lazy-container {
    padding-top: 0;
    margin-bottom: 0;

    min-height: 564px;

    border-radius: 0;
  }

  .projects__item .item__lazy-placeholder {
    border-radius: 0;
  }

  .projects__item .item__lazy-img {
    border-radius: 0;
  }
}